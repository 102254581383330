import React from "react";
import { Input, Select } from "../../../../UI/Utitls/Input";
import { ButtonFill } from "../../../../UI/Utitls/Button";

const MerchantInfo = ({
  newMerchant,
  newMerChantErrors,
  handleChange_,
  handleSubmit,
  merchantUsers,
  handleExistingUser,
}) => {
  // console.log(merchantUsers);
  return (
    <div className="flex flex-col gap-[23px]">
      <Input
        label={"Merchant Name"}
        value={newMerchant.name}
        handleChange={handleChange_}
        name={"name"}
        error={newMerChantErrors.name}
      />

      <Input
        label={"Description"}
        value={newMerchant.description}
        handleChange={handleChange_}
        name={"description"}
        error={newMerChantErrors.description}
      />

      <Select
        label={"Assign to user"}
        value={newMerchant.existingUsername}
        handleChange={(e) => handleExistingUser(e.name)}
        name={"existingUsername"}
        error={newMerChantErrors.existingUsername}
        options={merchantUsers}
      />

      <Input
        label={"Merchant ID"}
        value={newMerchant.mid}
        handleChange={handleChange_}
        name={"mid"}
        error={newMerChantErrors.mid}
      />
      <div className="flex justify-end">
        <ButtonFill
          label={"Next"}
          classes="h-[40px] text-[#fff] max-w-[120px]"
          action={() => handleSubmit("Merchant Info", true)}
          loading={false}
          active={true}
        />
      </div>
    </div>
  );
};

export default MerchantInfo;

import React from "react";
import { Input } from "../../../../UI/Utitls/Input";
import { ButtonFill } from "../../../../UI/Utitls/Button";

const ContactInfo = ({
  newMerchant,
  newMerChantErrors,
  handleChange_,
  handleSubmit,
}) => {
  return (
    <div className="flex flex-col gap-[23px]">
      <Input
        label={"Email address"}
        value={newMerchant.email}
        handleChange={handleChange_}
        name={"email"}
        error={newMerChantErrors.email}
      />
      <Input
        label={"Phone Number"}
        value={newMerchant.phone}
        handleChange={handleChange_}
        name={"phone"}
        error={newMerChantErrors.phone}
      />
      <Input
        label={"Address"}
        value={newMerchant.address}
        handleChange={handleChange_}
        name={"address"}
        error={newMerChantErrors.address}
      />
      <Input
        label={"City"}
        value={newMerchant.city}
        handleChange={handleChange_}
        name={"city"}
        error={newMerChantErrors.city}
      />

      <div className="flex justify-end">
        <ButtonFill
          label={"Next"}
          classes="h-[40px] text-[#fff] max-w-[120px]"
          action={() => handleSubmit("Contact Info", true)}
          loading={false}
          active={true}
        />
      </div>
    </div>
  );
};

export default ContactInfo;

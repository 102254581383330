import React from "react";
import AuthLayout2 from "../UI/Layouts/AuthLayout2";
import AuthForm from "../UI/Forms/AuthForm";
// import useAuthentication from "../../Hooks/useAuthentication";
// import { Link } from "react-router-dom";
import {
  // Input,
  OtpInput,
} from "../UI/Utitls/Input";
// import { BiArrowBack } from "react-icons/bi";
import useOtp from "../../Hooks/useOtp";

const SignupOtp = () => {
  const {
    loading,
    active,
    otp,
    setOtp,
    // countdown,
    timer,
    reset,
  } = useOtp();
  return (
    <AuthLayout2>
      <AuthForm
        handleSubmit={() => {}}
        loading={loading}
        active={active}
        labels={{
          title: "OTP Verification",
          subtitle: "Enter the 6 digits sent to your email address",
        }}
        btn={"Continue"}
        centered={true}
        otp={true}
      >
        <div className="w-full">
          <OtpInput otp={otp} setOtp={setOtp} countdown={timer} reset={reset} />
        </div>
      </AuthForm>
    </AuthLayout2>
  );
};

export default SignupOtp;

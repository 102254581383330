import React, { useState, createContext, useContext } from "react";

const reactContext = createContext();

const DataContextProvider = (props) => {
  const [transactions, setTransactions] = useState({
    data: [],
    limit: 15,
    page: 1,
    total: 1,
    "more-pages": false,
  });

  const [users, setUsers] = useState({
    data: [],
    limit: 15,
    page: 1,
    total: 1,
    "more-pages": false,
  });
  const [merchants, setMerchants] = useState({
    data: [],
    limit: 15,
    page: 1,
    total: 1,
    "more-pages": false,
  });
  const [providers, setProviders] = useState({
    data: [],
    limit: 15,
    page: 1,
    total: 1,
    "more-pages": false,
  });
  const [terminals, setTerminals] = useState({
    data: [],
    limit: 15,
    page: 1,
    total: 1,
    "more-pages": false,
  });
  const [roles, setRoles] = useState({
    data: [],
    limit: 15,
    page: 1,
    total: 1,
    "more-pages": false,
  });
  const [permissions, setPermissions] = useState({
    data: [],
    limit: 15,
    page: 1,
    total: 1,
    "more-pages": false,
  });
  const [banks, setBanks] = useState([]);
  const [stats, setStats] = useState({});

  return (
    <reactContext.Provider
      value={{
        transactions,
        users,
        providers,
        merchants,
        terminals,
        roles,
        permissions,
        setTransactions,
        setUsers,
        setMerchants,
        setProviders,
        setTerminals,
        setRoles,
        setPermissions,
        banks,
        setBanks,
        stats,
        setStats,
      }}
      {...props}
    />
  );
};

const useDataContext = () => {
  const context = useContext(reactContext);
  return context;
};

export { DataContextProvider, useDataContext };

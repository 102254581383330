import React, { useState } from "react";
import Toolbar from "../Utitls/Toolbar";
import { useFlowContext } from "../../../State/context/FlowContext";
// import { ButtonFillCustom, ButtonOutline } from "../Utitls/Button";
// import Icon from "../../../Constants/icons";
// import CreateEntityLayout from "./CreateEntityLayout";
import ViewEntityLayout from "./ViewEntityLayout";

const EntityLayout = ({
  detail,
  MainContent,
  CreateScreen,
  allowedMenu,
  toolControllers,
}) => {
  const { setRowId, createScreen } = useFlowContext();
  const [showDetail, setShowDetail] = useState(false);

  if (showDetail)
    return (
      <ViewEntityLayout
        setRowId={setRowId}
        setShowDetail={setShowDetail}
        hasControl={true}
        loading={false}
      >
        {detail}
      </ViewEntityLayout>
    );
  else if (createScreen && CreateScreen) {
    return CreateScreen;
  } else {
    return (
      <>
        <Toolbar allowed={allowedMenu} toolControllers={toolControllers} />
        <MainContent viewDetails={() => setShowDetail(true)} />
      </>
    );
  }
};

export default EntityLayout;

import React, { useEffect, useState } from "react";
import CreateEntityLayout from "../../../UI/Layouts/CreateEntityLayout";
import vector from "../../../../Assets/vectors/user_vector.png";
import useMerchant from "../../../../Hooks/useMerchant";
// import { Input } from "../../../UI/Utitls/Input";
// import { ButtonFill, ButtonFillCustom } from "../../../UI/Utitls/Button";
import MerchantInfo from "./forms/MerchantInfo";
import ContactInfo from "./forms/Contact";
import BankInfo from "./forms/BankInfo";
import Icon from "../../../../Constants/icons";
import { useFlowContext } from "../../../../State/context/FlowContext";

const Nav = ({ active, label, setActive }) => {
  return (
    <span
      onClick={() => setActive(label)}
      className={`${
        active ? "text-[--dark] border-b border-b-[--dark]" : "text-[--shade]"
      } pb-1 text-[12px] cursor-pointer transition-all ease-in-out duration-150`}
    >
      {label}
    </span>
  );
};

const Animated = ({ children }) => {
  const [delay, setDelay] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDelay(true);
    }, 50);
  }, []);
  return (
    <div
      className={`${
        delay ? " translate-x-0 opacity-1" : "translate-x-[100px] opacity-0"
      } duration-150 transition-all ease-in-out`}
    >
      {children}
    </div>
  );
};

const CreateMerchant = () => {
  const {
    contactInfo,
    merchantInfo,
    bankInfo,
    newMerChantErrors,
    active,
    loading,
    merchantUsers,
    updateBankInfo,
    updateContactInfo,
    updateMerchantInfo,
    submitNewMerchant,
    handleExistingUser,
    banks,
    selectBank,
  } = useMerchant();

  const { setCreateScreen } = useFlowContext();

  return (
    <div className="">
      <span
        onClick={() => setCreateScreen(false)}
        className="flex items-center gap-[8px] text-[12px] cursor-pointer"
      >
        <Icon name={"back"} className={"w-[18px]"} />
        Back
      </span>
      <CreateEntityLayout title={"Create New Merchant"} vector={vector}>
        <div className="flex gap-5">
          <Nav
            active={active === "Merchant Info"}
            label={"Merchant Info"}
            setActive={() => submitNewMerchant("Merchant Info")}
          />
          <Nav
            active={active === "Contact Info"}
            label={"Contact Info"}
            setActive={() => submitNewMerchant("Contact Info")}
          />
          <Nav
            active={active === "Bank Info"}
            label={"Bank Info"}
            setActive={() => submitNewMerchant("Bank Info")}
          />
        </div>
        {active === "Merchant Info" && (
          <Animated>
            <MerchantInfo
              newMerchant={merchantInfo}
              newMerChantErrors={newMerChantErrors}
              handleChange_={updateMerchantInfo}
              handleExistingUser={handleExistingUser}
              handleSubmit={submitNewMerchant}
              merchantUsers={merchantUsers}
            />
          </Animated>
        )}
        {active === "Contact Info" && (
          <Animated>
            <ContactInfo
              newMerchant={contactInfo}
              newMerChantErrors={newMerChantErrors}
              handleChange_={updateContactInfo}
              handleSubmit={submitNewMerchant}
            />
          </Animated>
        )}
        {active === "Bank Info" && (
          <Animated>
            <BankInfo
              newMerchant={bankInfo}
              newMerChantErrors={newMerChantErrors}
              handleChange_={updateBankInfo}
              loading={loading}
              handleSubmit={submitNewMerchant}
              banks={banks}
              selectBank={selectBank}
            />
          </Animated>
        )}
      </CreateEntityLayout>
    </div>
  );
};

export default CreateMerchant;

import React from "react";
// import ActiveOverViewCards from "../../../UI/Cards/ActiveOverViewCards";
// import images from "../../../../Constants/images";
import Analyst from "../../../UI/Layouts/Analyst";
// import { useDataContext } from "../../../../State/context/DataContext";
// 
const ActiveEntities = () => {
  // const { merchants } = useDataContext();
  return (
    <Analyst title={"Most Active Entities of the week"}>
      {/* {[0, 1, 2, 3, 4].map((_a, n) => (
        <ActiveOverViewCards
          key={n}
          isLast={n + 1 === 5}
          rating={4}
          image={""}
          title={"Access Bank"}
        />
      ))} */}
      <div className="flex justify-center items-center w-full">
        <span>No Data Yet </span>
      </div>
    </Analyst>
  );
};

export default ActiveEntities;

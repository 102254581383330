export const area_data = [
  {
    month: "Jan",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Feb",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Mar",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Apr",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "May",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Jun",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Jul",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Aug",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Sept",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Oct",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Nov",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
  {
    month: "Dec",
    UPSL: 0,
    NIBSS: 0,
    INTERSWITCH: 0,
    amt: 0,
  },
];

export const barchart_data = [
  {
    date: "Mon",
    amClass: {
      success: 0,
    },
    pmClass: {
      error: 0,
    },
  },
  {
    date: "Tue",
    amClass: {
      success: 0,
    },
    pmClass: {
      error: 0,
    },
  },
  {
    date: "Wed",
    amClass: {
      success: 0,
    },
    pmClass: {
      error: 0,
    },
  },
  {
    date: "Thu",
    amClass: {
      success: 0,
    },
    pmClass: {
      error: 0,
    },
  },
  {
    date: "Fri",
    amClass: {
      success: 0,
    },
    pmClass: {
      error: 0,
    },
  },
  {
    date: "Sat",
    amClass: {
      success: 0,
    },
    pmClass: {
      error: 0,
    },
  },
  {
    date: "Sun",
    amClass: {
      success: 0,
    },
    pmClass: {
      error: 0,
    },
  },
];

import React from "react";
import { AuthContextProvider } from "./context/AuthContext";
import { DataContextProvider } from "./context/DataContext";
import { FlowContextProvider } from "./context/FlowContext";

const AppProvider = ({ children }) => {
  return (
    <AuthContextProvider>
      <DataContextProvider>
        <FlowContextProvider>{children}</FlowContextProvider>
      </DataContextProvider>
    </AuthContextProvider>
  );
};

export default AppProvider;

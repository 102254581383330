import React from "react";
import "./toggle.css";

const Toggle = ({ status, activate, id }) => {
  // console.log(id);
  if (status === 1)
    return (
      <div className="toggle-container flex cursor-pointer items-center justify-end relative ">
        <span
          className={`w-[70px] rounded-[22px] transition-all duration-150 ease-in-out h-[22px] px-2 bg-[#4834D4] relative p-1 flex items-center`}
        >
          <span className="text-[7px] text-white w-full text-end flex justify-start items-center">
            Activated
          </span>
          <span
            className={`w-[18px]  h-[18px] transition-all duration-150 ease-in-out rounded-full cursor-pointer bg-white right-1 absolute `}
          ></span>
        </span>
        <span className="tooltip text-[10px] overflow-x-hidden py-[20px] absolute p-[10px] shadow-md w-fit bg-[#fff] bottom-[30px] right-0 ">
          Deactivating this provider will disconnect <br /> all associated
          controls the provider has.
        </span>
      </div>
    );
  else
    return (
      <div
        onClick={() => activate(id)}
        className="toggle-container flex cursor-pointer items-center justify-end"
      >
        <span
          className={`w-[70px] rounded-[22px] transition-all duration-150 ease-in-out h-[22px] px-2 bg-[#4641415e] relative p-1 flex items-center`}
        >
          <span
            className={`w-[18px] h-[18px] transition-all duration-150 ease-in-out rounded-full cursor-pointer bg-white left-1 absolute `}
          ></span>
          <span className="text-[7px] text-white w-full text-end flex justify-end items-center">
            Activate
          </span>
        </span>
        <span className="tooltip text-[10px] overflow-x-hidden py-[20px] absolute p-[10px] shadow-md w-fit bg-[#fff] bottom-[30px] right-0 ">
          Activating this provider will establish <br /> a new connection to the
          associated provider.
        </span>
      </div>
    );
};

export default Toggle;

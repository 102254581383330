import React from // { useState }
"react";
// import ProviderTable from "../UI/Tables/ProvidersTable";
import EntityLayout from "../UI/Layouts/EntityLayout";
// import Service from "../Components/Sections/Provider/Provider";
import Provider from "../Components/Sections/Provider/Provider";
import ServiceTable from "../UI/Tables/ServiceTable";

const Tables = ({ viewDetails }) => {
  // const [page, setPage] = useState("service");
  return (
    <div className="flex flex-col">
      {/* <div className="flex mb-[20px]">
        <span
          onClick={() => setPage("service")}
          className={`flez items-center justify-center rounded-[5px] p-[10px] cursor-pointer ${
            page === "service" ? "border-[#4834D4] border text-[#4834D4]" : "text-[#333]"
          }`}
        >
          Services
        </span>
        <span
          onClick={() => setPage("provider")}
          className={`flez items-center justify-center rounded-[5px] p-[10px] cursor-pointer ${
            page === "provider" ? "border-[#4834D4] border text-[#4834D4]" : "text-[#333]"
          }`}
        >
          Providers
        </span>
      </div> */}
      <ServiceTable showProvider={viewDetails} />
      {/* {page === "provider" ? (
        <ProviderTable showProvider={viewDetails} />
      ) : (
        <ServiceTable showProvider={viewDetails} />
      )} */}
    </div>
  );
};

const Providers = () => {
  return (
    <EntityLayout
      detail={<Provider />}
      MainContent={({ viewDetails }) => <Tables viewDetails={viewDetails} />}
    />
  );
};

export default Providers;

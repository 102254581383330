import React from "react";
import {
  PieChart,
  Pie,
  // Legend
} from "recharts";

const data = [
  { name: "High", value: 400, fill: "#DC2727" },
  { name: "Low", value: 300, fill: "#25B1BA" },
  { name: "Medium", value: 300, fill: "#2C2A89" },
];
// const ColoConfig = (value, entry) => {
//   return (
//     <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
//       {value}
//     </span>
//   );
// };

const Donut = () => {
  return (
    <PieChart width={180} height={180}>
      <Pie data={data} dataKey="value" outerRadius={80} innerRadius={60} />
    </PieChart>
  );
};

export default Donut;

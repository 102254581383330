// import React from "react";
import { useDataContext } from "../State/context/DataContext";
import { toast } from "react-hot-toast";
import useClient from "../Helpers/axiosInstance";

// const userService = () => {
export class userService {
  dataContext = useDataContext();
  client = useClient();
  getUser = async (id) => {
    try {
      const res = await this.client.get(`/users/${id}`);
      console.log(res);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  createUser = async (data) => {
    try {
      const res = await this.client.post(`/users`, data);
      toast.success("User Created");
      this.fetchUsers();
      return { message: "success", data: res.data };
    } catch (err) {
      if (
        err.response &&
        err.response.data.message &&
        err.response.data.message === "user already exists"
      )
        toast.error("User already exist");
      else toast.error("Failed to create user");
      return { message: "failed" };
    }
  };
  filterUsersById = (id) => {
    const users = this.dataContext.users;
    const user = users.data.filter((u) => u.uuid === id);
    return user[0];
  };
  fetchUsers = async () => {
    try {
      const res = await this.client.get("/users");
      // console.log(res);
      this.dataContext.setUsers(res.data);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getUserPages = async (page, limit) => {
    try {
      const res = await this.client.get(
        `/users?page=${parseInt(page)}&limit=${parseInt(limit)}`
      );
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  searchUsers = async (search) => {
    try {
      const res = await this.client.get(`/users?search=${search}`);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  filterUsers = async (fromDate, toDate) => {
    try {
      const res = await this.client.get(`/users?from=${fromDate}&to${toDate}`);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
}

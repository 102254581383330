import React from "react";
import AuthLayout from "../UI/Layouts/AuthLayout";
import AuthForm from "../UI/Forms/AuthForm";
import useAuthentication from "../../Hooks/useAuthentication";
import { Input, PasswordInput } from "../UI/Utitls/Input";
import { Link } from "react-router-dom";
// import { AiFillCheckSquare, AiOutlineCheckSquare } from "react-icons/ai";
// import { Link } from "react-router-dom";

const Login = () => {
  const {
    form,
    errors,
    loginLayoutLabels,
    loading,
    active,
    handleChange,
    handleLogin,
  } = useAuthentication();

  return (
    <AuthLayout>
      <AuthForm
        initialValues={form}
        loading={loading}
        labels={loginLayoutLabels}
        handleSubmit={handleLogin}
        btn={"Login"}
        active={active}
      >
        <Input
          label={"Enter your email address"}
          value={form.email}
          handleChange={handleChange}
          name={"email"}
          error={errors.email}
        />
        <PasswordInput
          label={"Enter your password"}
          value={form.password}
          handleChange={handleChange}
          name={"password"}
          auto={true}
          error={errors.password}
        />
        <div className="flex justify-end">
          <Link
            className="text-end italic w-fit flex text-[--gray_text]"
            to={"/forgot-password"}
          >
            forgot password?
          </Link>
        </div>
      </AuthForm>
    </AuthLayout>
  );
};

export default Login;

import { useState } from "react";
import { useFlowContext } from "../State/context/FlowContext";

const useDelete = () => {
  const {
    // rowId,
    setRowId,
    setDeleteParams,
    setActiveModal,
    setModalType,
    deleteParams,
  } = useFlowContext();
  const [loading, setLoading] = useState(false);
  const deleteItem = () => {
    setLoading(true);
    if (deleteParams.title === "Role") {
    } else if (deleteParams.title === "Transaction") {
    } else if (deleteParams.title === "Terminal") {
    } else if (deleteParams.title === "Transaction") {
    }
    setLoading(false);
  };
  const cancel = () => {
    setRowId("");
    setDeleteParams({ title: "", label: "" });
    setActiveModal(false);
    setModalType("");
  };
  return { loading, deleteItem, cancel };
};

export default useDelete;

import React from "react";
import AuthLayout from "../UI/Layouts/AuthLayout";
import AuthForm from "../UI/Forms/AuthForm";
import useAuthentication from "../../Hooks/useAuthentication";
// import { Input, PasswordInput } from "../UI/Utitls/Input";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
// import { AiFillCheckSquare, AiOutlineCheckSquare } from "react-icons/ai";
// import { Link } from "react-router-dom";

const NewPassword = () => {
  const {
    // passwordErrors,
    // passwords,
    resetLayoutLabels,
    loading,
    // active,
    // handlePasswordsChange,
  } = useAuthentication();

  return (
    <AuthLayout>
      <AuthForm
        loading={loading}
        labels={resetLayoutLabels}
        // handleSubmit={() => {}}
        // btn={"Reset Password"}
        hideBtn={true}
        active={false}
        base_link={
          <Link
            to={"/login"}
            className="flex items-center text-[#666666] gap-[9px] cursor-pointer w-fit"
          >
            <BiArrowBack /> Back to login
          </Link>
        }
      >
        {/* <PasswordInput
          label={"New password"}
          value={passwords.new_password}
          handleChange={handlePasswordsChange}
          name={"new_password"}
          error={passwordErrors.new_password}
        />
        <PasswordInput
          label={"Confirm password"}
          value={passwords.confirm_password}
          handleChange={handlePasswordsChange}
          name={"confirm_password"}
          error={passwordErrors.confirm_password}
        /> */}
      </AuthForm>
    </AuthLayout>
  );
};

export default NewPassword;

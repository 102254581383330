import React, { useState, createContext, useContext } from "react";

const reactContext = createContext();

const FlowContextProvider = (props) => {
  const [page, setPage] = useState("Overview");
  const [rowId, setRowId] = useState("");
  const [createScreen, setCreateScreen] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [deleteParams, setDeleteParams] = useState({ title: "", label: "" });
  const [rowMenu, setRowMenu] = useState("");
  const [entity, setEntity] = useState({
    id: "",
    name: "",
    descrption: { label: "", value: "" },
    type: "",
    hasActions: false,
    activate: () => {},
    deactivate: () => {},
  });

  return (
    <reactContext.Provider
      value={{
        page,
        setPage,
        rowId,
        setRowId,
        createScreen,
        setCreateScreen,
        activeModal,
        setActiveModal,
        modalType,
        setModalType,
        deleteParams,
        setDeleteParams,
        rowMenu,
        setRowMenu,
        entity,
        setEntity,
      }}
      {...props}
    />
  );
};

const useFlowContext = () => {
  const context = useContext(reactContext);
  return context;
};

export { FlowContextProvider, useFlowContext };

import React from "react";
import Loader from "./Loader";

export const ButtonFill = ({ label, classes, action, loading, active }) => {
  return (
    <span
      onClick={action}
      className={`${
        active ? "bg-[--dark]" : "bg-[--light_dark]"
      } rounded-[12px] w-full cursor-pointer font-[600] flex justify-center items-center ${classes}`}
    >
      {loading ? <Loader color={"#fff"} size={[30, 30]} /> : label}
    </span>
  );
};
export const ButtonFillCustom = ({
  label,
  classes,
  action,
  loading,
}) => {
  return (
    <span
      onClick={action}
      className={` rounded-[12px] cursor-pointer font-[600] flex justify-center items-center ${classes}`}
    >
      {loading ? <Loader color={"#fff"} size={[30, 30]} /> : label}
    </span>
  );
};

export const ButtonOutline = ({ label, classes, action }) => {
  return (
    <span
      onClick={action}
      className={`border  rounded-[12px] cursor-pointer font-[600] flex justify-center items-center ${classes}`}
    >
      {label}
    </span>
  );
};

import React, { useEffect } from "react";
import Sidebar from "../../Components/Navbar/Sidebar";
import Topbar from "../../Components/Navbar/Topbar";
import useDashboard from "../../../Hooks/useDashboard";

const AppLayout = ({ children }) => {
  const { getDashBoardData } = useDashboard();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    getDashBoardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full grid grid-cols-12 h-screen ">
      <div className="col-span-3">
        <div className="fixed top-0 left-0 bottom-0 p-[24px] h-screen w-[25%]  ">
          <Sidebar />
        </div>
      </div>
      <div className="col-span-9 h-full p-[24px]  ">
        <div className="border rounded-[12px] flex flex-col gap-[56px] p-[20px] pb-[29px]">
          <Topbar />
          {children}
        </div>
      </div>
    </div>
  );
};

export default AppLayout;

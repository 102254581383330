import React from "react";

const icons = {
  chevron: require("../Assets/icons/chevron.png"),
  dashboard: require("../Assets/icons/dashboard.png"),
  active_dashboard: require("../Assets/icons/activeDashboard.png"),
  transaction: require("../Assets/icons/transaction.png"),
  active_transaction: require("../Assets/icons/activeTransaction.png"),
  user_management: require("../Assets/icons/IdentificationBadge.png"),
  merchant: require("../Assets/icons/merchant.png"),
  active_merchant: require("../Assets/icons/activeMerchant.png"),
  provider: require("../Assets/icons/provider.png"),
  active_provider: require("../Assets/icons/activeProvider.png"),
  terminal: require("../Assets/icons/terminal.png"),
  active_terminal: require("../Assets/icons/activeTerminal.png"),
  report: require("../Assets/icons/report.png"),
  active_report: require("../Assets/icons/activeReport.png"),
  role: require("../Assets/icons/role.png"),
  active_role: require("../Assets/icons/activeRole.png"),
  create_user: require("../Assets/icons/createUser.png"),
  active_create_user: require("../Assets/icons/activeCreateUser.png"),
  user_mng: require("../Assets/icons/createUser.png"),
  active_user_mng: require("../Assets/icons/activeUserMng.png"),
  logout: require("../Assets/icons/logout.png"),
  bell: require("../Assets/icons/bell.png"),
  star: require("../Assets/icons/star.png"),
  active_star: require("../Assets/icons/activeStar.png"),
  search: require("../Assets/icons/search.png"),
  calender: require("../Assets/icons/calender.png"),
  filter: require("../Assets/icons/filter.png"),
  cloud: require("../Assets/icons/cloud.png"),
  eye: require("../Assets/icons/eye.png"),
  delete: require("../Assets/icons/delete.png"),
  menu: require("../Assets/icons/menu.png"),
  check: require("../Assets/icons/check.png"),
  active_check: require("../Assets/icons/activeCheck.png"),
  trash: require("../Assets/icons/trash.png"),
  back: require("../Assets/icons/back.png"),
  pen: require("../Assets/icons/pen.png"),
  swap: require("../Assets/icons/swap.png"),
};

const Icon = ({ name, className, onClick }) => {
  return (
    <img
      className={className}
      onClick={() => {
        onClick && onClick();
      }}
      src={icons[name]}
      alt=""
    />
  );
};

export default Icon;

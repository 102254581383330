import React from "react";
import EntityLayout from "../UI/Layouts/EntityLayout";
import RoleTable from "../UI/Tables/RoleTable";
import CreateRole from "../Components/Sections/Roles/CreateRole";

const Role = () => {
  return (
    <EntityLayout
      CreateScreen={<CreateRole />}
      MainContent={({ viewDetails }) => {
        return <RoleTable editRole={viewDetails} />;
      }}
    />
  );
};

export default Role;

import React, { useState } from "react";
import { useDataContext } from "../../../State/context/DataContext";
import { useFlowContext } from "../../../State/context/FlowContext";
import Table from "./Table";
import moment from "moment/moment";

const header = [
  { name: "Role", ratio: 20 },
  { name: "Created Date", ratio: 55 },
  { name: "Updated Date", ratio: 10 },
];

// const CustomCell = ({ val, className }) => {
//   return <span className={className}>{val}</span>;
// };

const RoleTable = ({ editRole }) => {
  const { roles } = useDataContext();
  const { setRowId } = useFlowContext();
  const [checkedList, setCheckedList] = useState([]);
  const append = (id) => setCheckedList([...checkedList, id]);
  const remove = (id) =>
    setCheckedList([...checkedList.filter((c) => c === id)]);

  const viewRow = (id) => {
    setRowId(id);
    // showTerminal();
  };

  const switchPage = async (page) => {
    // const res = await service.getMerchantPages(page, 10);
    // // console.log(res.data);
    // if (res.message === "success") {
    //   setMerchants(res.data);
    // }
    return { message: "res.message" };
  };

  const createdata = (id, role, created_at, updated_at) => {
    const getParam = (val) => {
      return moment(val).fromNow();
    };

    const row = [
      { name: role, id: id, root: "Role" },
      { name: getParam(created_at), id: id, root: "Created Date" },
      { name: getParam(updated_at), id: id, root: "Updated Date" },
    ];

    return row;
  };
  const data = roles
    ? roles?.data?.map((r) =>
        createdata(r.id, r.name, r.created_at, r.updated_at)
      )
    : [];

  return (
    <Table
      checkedList={checkedList}
      append={append}
      remove={remove}
      data={data}
      header={header}
      viewRow={viewRow}
      hidePagination={true}
      fullwidth={true}
      paginationData={{
        limit: roles?.limit,
        page: roles?.page,
        total: roles?.total,
        "more-pages": roles["more-pages"],
        navigatePage: switchPage,
      }}
      deleteParam={{
        title: "Role",
        label:
          "You are about to delete a role. Please click confirm to delete the role",
      }}
    />
  );
};

export default RoleTable;

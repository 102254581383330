import React, { useState } from "react";
import MerchantTable from "../UI/Tables/MerchantTable";
import Merchant from "../Components/Sections/Merchant/Merchant";
import EntityLayout from "../UI/Layouts/EntityLayout";
import CreateMerchant from "../Components/Sections/Merchant/CreateMerchant";
import { merchantService } from "../../Services/merchants.service";
import { useDataContext } from "../../State/context/DataContext";
import toast from "react-hot-toast";

const Merchants = () => {
  const { setMerchants } = useDataContext();
  const service = new merchantService();
  const [donwloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const searchMerchant = async (search) => {
    const res = await service.searchMerchant(search);
    // console.log(res.data);
    if (res.message === "success") {
      setMerchants(res.data);
    }
    return { message: res.message };
  };
  const filterMerchant = async (search) => {
    const res = await service.filterMerchant(search);
    // console.log(res.data);
    if (res.message === "success") {
      setMerchants(res.data);
    }
    return { message: res.message };
  };

  const download = async () => {
    setDownloading(true);
    const res = await service.download();
    if (res.message === "success") {
      toast.success("Downloaded successfully");
    } else toast.error("Download failed");
    setDownloading(false);
  };

  const bulkupload = async (data) => {
    // console.log("data")
    setUploading(true);
    const res = await service.bulkUpload(data);
    if (res.message === "success") {
      toast.success("Uploaded successfully");
    } else toast.error(res.response);
    setUploading(false);
  };

  return (
    <EntityLayout
      detail={<Merchant />}
      CreateScreen={<CreateMerchant />}
      allowedMenu={["search", "filter", "download", "more", "enable-create"]}
      toolControllers={{
        handleSearch: searchMerchant,
        handleFilter: filterMerchant,
        download: download,
        donwloading: donwloading,
        uploading: uploading,
        upload: bulkupload,
      }}
      MainContent={({ viewDetails }) => {
        return <MerchantTable showMerchant={viewDetails} />;
      }}
    />
  );
};

export default Merchants;

import React, { useRef, useState } from "react";
import Icon from "../../../Constants/icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const CalenderFilter = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  // console.log(months.indexOf(""));
  const ref1 = useRef();
  const ref2 = useRef();
  const [start, setStart] = useState(new Date(year, month, day));
  const [startDisplay, setStartDisplay] = useState(`${months[month]}, ${day}`);
  const [end, setEnd] = useState(new Date(year, month, day));
  const [endDisplay, setEndDisplay] = useState(`${months[month]}, ${day}`);
  const [dateToggle, setDateToggle] = useState({
    vissble: false,
    type: "",
  });
  const changeStartDate = (e) => {
    const val = e.toString();
    // console.log(typeof val);
    const m = months.indexOf(val?.slice(4, 7));
    const y = val?.slice(11, 15);
    const d = val?.slice(8, 10);
    setStart(new Date(y, m, d));
    setStartDisplay(`${val?.slice(4, 7)}, ${d}`);
  };
  const changeEndDate = (e) => {
    const val = e.toString();
    // console.log(typeof val);
    const m = months.indexOf(val?.slice(4, 7));
    const y = val?.slice(11, 15);
    const d = val?.slice(8, 10);
    setEnd(new Date(y, m, d));
    // console.log(val?.slice(4, 7));
    setEndDisplay(`${val?.slice(4, 7)}, ${d}`);
  };
  const toggle = (type) => {
    setDateToggle({
      vissble:
        dateToggle.vissble === true && dateToggle.type === type
          ? !dateToggle.vissble
          : true,
      type: type,
    });
  };
  window.addEventListener("click", (e) => {
    if (
      dateToggle.vissble &&
      !ref1.current?.contains(e.target) &&
      !ref2.current?.contains(e.target)
    ) {
      setDateToggle({
        vissble: false,
        type: "",
      });
    }
  });
  return (
    <div className="h-[44px]  w-fit flex gap-[8px] rounded-[8px] border items-center px-[10px] border-[#E6E6E6]">
      <div className="text-[#333] whitespace-nowrap relative flex items-center gap-[20px] ">
        <div
          ref={ref1}
          className="relative flex"
          onClick={() => toggle("start")}
        >
          <Icon name={"calender"} className={"w-[24px] h-[24px]"} />
          <span className="cursor-pointer">{startDisplay}</span>
          {dateToggle.vissble && dateToggle.type === "start" && (
            <div className="absolute top-[30px] right-0 bg-[#fff] z-[9999]">
              <Calendar onChange={changeStartDate} value={start} />
            </div>
          )}
        </div>
        -
        <div ref={ref2} className="relative flex" onClick={() => toggle("end")}>
          <Icon name={"calender"} className={"w-[24px] h-[24px]"} />
          <span className="cursor-pointer">{endDisplay}</span>
          {dateToggle.vissble && dateToggle.type === "end" && (
            <div className="absolute top-[30px] right-0 bg-[#fff] z-[9999]">
              <Calendar onChange={changeEndDate} value={end} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalenderFilter;

// import React from "react";
// import { toast } from "react-hot-toast";
import { useDataContext } from "../State/context/DataContext";
import useClient from "../Helpers/axiosInstance";

export class terminalService {
  dataContext = useDataContext();
  client = useClient();
  fetchTerminals = async () => {
    try {
      const res = await this.client.get("/terminals");
      this.dataContext.setTerminals(res.data);
      return { message: "success" };
    } catch (err) {
      console.log(err);
      return { message: "failed" };
    }
  };
  createTerminal = async (data) => {
    try {
      await this.client.post("/terminals", data);
      this.fetchTerminals();
      return { message: "success" };
    } catch (err) {
      console.log(err);
      return { message: "failed" };
    }
  };
  getTerminal = async (id) => {
    try {
      const res = await this.client.get(`/terminals/${id}`);
      return { message: "success", data: res.data };
    } catch (err) {
      console.log(err);
      return { message: "failed" };
    }
  };
  updateTerminal = async (id) => {
    try {
      const res = await this.client.get(`/terminal/${id}`);
      return { message: "success", data: res.data };
    } catch (err) {
      console.log(err);
      return { message: "failed" };
    }
  };
  getTerminalPages = async (page, limit) => {
    try {
      const res = await this.client.get(
        `/terminals?page=${parseInt(page)}&limit=${parseInt(limit)}`
      );
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  searchTerminal = async (search) => {
    try {
      const res = await this.client.get(`/terminals?query=${search}`);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  filterTerminal = async (fromDate, toDate) => {
    try {
      const res = await this.client.get(
        `/terminals?from=${fromDate}&to=${toDate}`
      );
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  download = async (data) => {
    try {
      const res = await this.client.get("/terminals/download");
      // console.log(res);
      const blob = new Blob([res.data], { type: "application/zip" });

      // Create a temporary URL for the Blob object

      console.log("blob", blob);
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute of the link to the temporary URL
      link.href = url;

      // Set the download attribute to specify the filename
      link.download = "merchant-doc.zip";

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger a click event on the link to start the download
      link.click();
      console.log("download link", link);

      // Remove the link from the body
      document.body.removeChild(link);

      // Revoke the temporary URL to free up memory
      URL.revokeObjectURL(url);
      return { message: "success" };
    } catch (err) {
      return { message: "failed" };
    }
  };
  bulkUpload = async (data) => {
    try {
      const res = await this.client.post("/terminals/upload", data);
      // this.fetchMerchants();
      return { message: "success", data: res.data };
    } catch (err) {
      // console.log(err.response.status);
      if (err.response.status === 422)
        return { message: "failed", response: "Unable to process document" };
      else
        return {
          message: "failed",
          response: "Something went wrong. Please try again.",
        };
    }
  };
}

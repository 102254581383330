import React from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  // Line,
  Area,
  // Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  // Legend,
} from "recharts";
import { area_data } from "../../../Constants/dummy_data";
// import { Select } from "../../UI/Utitls/Input";
import ImageLayout from "../../UI/Layouts/ImageLayout";
import { useDataContext } from "../../../State/context/DataContext";

const CustomTooltip = ({ active, payload, label }) => {
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount).replace("NGN", "");
  };
  if (active && payload && payload.length) {
    // console.log(payload);
    return (
      <ImageLayout
        bg={"chat"}
        className={"flex flex-col w-[220px] h-[110px] pt-[20px] px-[27px]"}
      >
        <p className=" text-[10px] text-[rgba(60,60,67,0.60)]">This month</p>
        <p className="">₦{toPrice(payload[0].value)} M</p>
        <p className="text-[10px] text-[rgba(60,60,67,0.60)]">{label}</p>
      </ImageLayout>
    );
  }

  return null;
};
const empty = area_data;
const AreaChart = () => {
  const { stats } = useDataContext();
  // console.log("stats", stats);
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount).replace("NGN", "");
  };
  const area_data =
    stats && stats?.sumOfTransactionsMonthly && Object.keys(stats).length > 0
      ? Object.keys(stats?.sumOfTransactionsMonthly)?.map((s) => {
          return {
            month: s.slice(0, 3),
            UPSL: parseInt(toPrice(stats?.sumOfTransactionsMonthly[s] / 1)),
            NIBSS: parseInt(toPrice(stats?.sumOfTransactionsMonthly[s] / 1)),
            INTERSWITCH: parseInt(
              toPrice(stats?.sumOfTransactionsMonthly[s] / 1)
            ),
            amt: parseInt(toPrice(stats?.sumOfTransactionsMonthly[s] / 1)),
          };
        })
      : empty;
  // console.log("", area_data);

  return (
    <div className="w-full p-[22px] h-[283.164px]">
      <div className="flex  items-center justify-between">
        <div className="flex flex-col">
          <span className="text-[#333] text-[14px] ">Total Transaction</span>
          <span className="text-[24px] font-[700]">
            ₦{toPrice(stats?.totalVolume ? stats?.totalVolume : 0)}
          </span>
        </div>
        {/* <Select
          value={"Month"}
          handleChange={() => {}}
          name={"month"}
          error={""}
          options={["Jan", "Feb", "Mar", "Apr", "May"]}
        /> */}
      </div>
      <div className="w-full h-[200px] mt-[26px] text-[#563bb881]">
        <ResponsiveContainer>
          <ComposedChart
            width={500}
            height={200}
            data={area_data}
            margin={{
              top: 0,
              right: 20,
              bottom: 20,
              left: 0,
            }}
          >
            <defs>
              <linearGradient id="colorUPSL" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#fff" stopOpacity={1} />
                <stop offset="95%" stopColor="#fff" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorINTERSWITCH" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#563bb881" stopOpacity={0.1} />
                <stop offset="95%" stopColor="#563bb881" stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id="colorNIBSS" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#7459D9" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#7459D9" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid
              vertical={false}
              horizontal={["#ECE9F1", "#fff"]}
              verticalFill={["#fff", "#fff"]}
              fillOpacity={1}
            />
            <XAxis
              dataKey="month"
              tickLine={false}
              padding={{ top: 30, bottom: 30 }}
              dy={20}
              axisLine={{ stroke: "#fff", fillOpacity: 0.1 }}
            />
            <YAxis
              unit={" M"}
              dx={-20}
              axisLine={{ stroke: "#fff" }}
              tickLine={false}
              tick={{ fontSize: 10 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="UPSL"
              stroke="#ECE9F1"
              fillOpacity={1}
              strokeWidth={4}
              fill="url(#colorUPSL)"
            />
            <Area
              type="monotone"
              dataKey="INTERSWITCH"
              strokeWidth={4}
              stroke="#563bb881"
              fillOpacity={1}
              fill="url(#colorINTERSWITCH)"
            />
            <Area
              type="monotone"
              dataKey="NIBSS"
              strokeWidth={4}
              stroke="#7459D9"
              fillOpacity={1}
              fill="url(#colorNIBSS)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AreaChart;

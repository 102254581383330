import React from "react";
import UserInfo from "../Components/Sections/User/UserInfo";
import UserTable from "../UI/Tables/UserTable";
import EntityLayout from "../UI/Layouts/EntityLayout";
import { useDataContext } from "../../State/context/DataContext";
import { userService } from "../../Services/user.service";

const ManageUser = () => {
  const { setUsers } = useDataContext();
  const service = new userService();
  const searchUsers = async (search) => {
    const res = await service.searchUsers(search);
    // console.log(res.data);
    if (res.message === "success") {
      setUsers(res.data);
    }
    return { message: res.message };
  };
  const filterUsers = async (search) => {
    const res = await service.filterUsers(search);
    // console.log(res.data);
    if (res.message === "success") {
      setUsers(res.data);
    }
    return { message: res.message };
  };
  return (
    <EntityLayout
      detail={<UserInfo />}
      CreateScreen={null}
      allowedMenu={["search"]}
      toolControllers={{
        handleSearch: searchUsers,
        handleFilter: filterUsers,
      }}
      MainContent={({ viewDetails }) => {
        return <UserTable showUser={viewDetails} />;
      }}
    />
  );
};

export default ManageUser;

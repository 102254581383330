import React from "react";
import AuthLayout from "../UI/Layouts/AuthLayout";
import AuthForm from "../UI/Forms/AuthForm";
// import useAuthentication from "../../Hooks/useAuthentication";
import {
  Input,
  // PasswordInput,
  Select,
} from "../UI/Utitls/Input";
// import { Link } from "react-router-dom";
// import { BiArrowBack } from "react-icons/bi";
import useGetStarted from "../../Hooks/useGetStarted";
// import { AiFillCheckSquare, AiOutlineCheckSquare } from "react-icons/ai";
// import { Link } from "react-router-dom";

const UserSignup = () => {
  const {
    signupErrors,
    signup,
    handleSingupSelect,
    loading,
    active,
    handleSignUpChange,
  } = useGetStarted();

  return (
    <AuthLayout>
      <AuthForm
        loading={loading}
        labels={{
          title: "Get Started",
          subtitle: "Enter your personal details to continue",
        }}
        handleSubmit={() => {}}
        btn={"Create Account"}
        active={active}
      >
        <Input
          label={"First name"}
          value={signup.first_name}
          handleChange={handleSignUpChange}
          name={"first_name"}
          error={signupErrors.first_name}
        />
        <Input
          label={"Last name"}
          value={signup.last_name}
          handleChange={handleSignUpChange}
          name={"last_name"}
          error={signupErrors.last_name}
        />
        <Select
          label={"Your Role"}
          value={signup.role}
          name={"role"}
          options={["admin", "super admin"]}
          handleChange={handleSingupSelect}
          error={signupErrors.role}
        />
      </AuthForm>
    </AuthLayout>
  );
};

export default UserSignup;

const images = {
  authFrame: require("../Assets/images/authframe.png"),
  logo: (
    <span
      style={{
        fontSize: "24px",
        width: "127px",
        height: "28px",
        color: "#4834d4",
        fontWeight: "400",
        cursor: "pointer",
      }}
    >
      Middleware
    </span>
  ),
  avatar: require("../Assets/images/avatar.png"),
  bank: require("../Assets/images/bank.png"),
};

export default images;

import React, { useState } from "react";
import TerminalsTable from "../UI/Tables/TerminalsTable";
import EntityLayout from "../UI/Layouts/EntityLayout";
import Terminal from "../Components/Sections/Terminal/Terminal";
import CreateTerminal from "../Components/Sections/Terminal/CreateTerminal";
import { terminalService } from "../../Services/terminals.service";
import { useDataContext } from "../../State/context/DataContext";
import toast from "react-hot-toast";

const Terminals = () => {
  const { setTerminals } = useDataContext();
  const service = new terminalService();
  const [donwloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const searchTerminal = async (search) => {
    const res = await service.searchTerminal(search);
    // console.log(res.data);
    if (res.message === "success") {
      setTerminals(res.data);
    }
    return { message: res.message };
  };
  const filterTerminal = async (search) => {
    const res = await service.filterTerminal(search);
    // console.log(res.data);
    if (res.message === "success") {
      setTerminals(res.data);
    }
    return { message: res.message };
  };
  const download = async () => {
    setDownloading(true);
    const res = await service.download();
    if (res.message === "success") {
      toast.success("Downloaded successfully");
    } else toast.error("Download failed");
    setDownloading(false);
  };

  const bulkupload = async (data) => {
    // console.log("data")
    setUploading(true);
    const res = await service.bulkUpload(data);
    if (res.message === "success") {
      toast.success("Uploaded successfully");
    } else toast.error(res.response);
    setUploading(false);
  };
  return (
    <EntityLayout
      detail={<Terminal />}
      CreateScreen={<CreateTerminal />}
      allowedMenu={["search", "filter", "download", "more", "enable-create"]}
      toolControllers={{
        handleSearch: searchTerminal,
        handleFilter: filterTerminal,
        download: download,
        donwloading: donwloading,
        uploading: uploading,
        upload: bulkupload,
      }}
      MainContent={({ viewDetails }) => (
        <TerminalsTable showTerminal={viewDetails} />
      )}
    />
  );
};

export default Terminals;

import React, { useState } from "react";
// import { transactions } from "../../../Constants/table_data";
// import Icon from "../../../Constants/icons";
// import useTransaction from "../../../Hooks/useTransaction";
import Table from "./Table";
import { useDataContext } from "../../../State/context/DataContext";
import { useFlowContext } from "../../../State/context/FlowContext";
import { transactionService } from "../../../Services/transaction.service";

const header = [
  { name: "Transaction ID", ratio: 12 },
  { name: "Entity", ratio: 18 },
  { name: "Type", ratio: 10 },
  { name: "Provider", ratio: 10 },
  { name: "Amount", ratio: 15 },
  { name: "Date", ratio: 10 },
  { name: "Status", ratio: 10 },
];

const CustomCell = ({ val, className }) => {
  return <span className={className}>{val}</span>;
};

const TransactionTable = ({ showTransaction }) => {
  const { transactions, setTransactions } = useDataContext();
  const { setRowId, setEntity } = useFlowContext();
  const service = new transactionService();
  // console.log(transactions?.data);
  const [checkedList, setCheckedList] = useState([]);
  const append = (id) => setCheckedList([...checkedList, id]);
  const remove = (id) =>
    setCheckedList([...checkedList.filter((c) => c === id)]);
  const toPrice = (amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount).replace("NGN", "₦");
  };
  const createdata = (
    id,
    ref,
    entity,
    type,
    provider,
    amount,
    date,
    status
  ) => {
    const getParam = (val) => {
      if (val === "successful") {
        return (
          <CustomCell
            val={val}
            className={
              "w-fit p-3 bg-[#5db86c1a] text-[#5DB86C] h-[36px] flex items-center justify-center rounded-[36px]"
            }
          />
        );
      } else if (val === "pending") {
        return (
          <CustomCell
            val={val}
            className={
              "w-fit p-3 bg-[#fbab4a0e] text-[#FBAA4A] h-[36px] flex items-center justify-center rounded-[36px]"
            }
          />
        );
      } else if (val === "failed") {
        return (
          <CustomCell
            val={val}
            className={
              "w-fit p-3 bg-[#da34420a] text-[#da3442] h-[36px] flex items-center justify-center rounded-[36px]"
            }
          />
        );
      } else if (val === "Interswitch") {
        return <CustomCell val={val} className={" text-[#001956]"} />;
      } else if (val === "NIBSS" || val === "Nibbs") {
        return <CustomCell val={val} className={" text-[#7EA01A]"} />;
      } else if (val === "UPSL" || val === "Upsl") {
        return <CustomCell val={val} className={" text-[#229CFF]"} />;
      } else if (val === "Approved") {
        return (
          <CustomCell
            val={val}
            className={" text-[green] bg-[#00800015] p-3 py-1 rounded-md"}
          />
        );
      } else if (val === "Pending") {
        return (
          <CustomCell
            val={val}
            className={" text-[goldenrod] bg-[#daa52028] p-3 py-1 rounded-md"}
          />
        );
      } else if (val?.toLowerCase() === "declined") {
        return (
          <CustomCell
            val={val}
            className={" text-[#da2061] bg-[#da206126] p-3 py-1 rounded-md"}
          />
        );
      } else
        return <span className="tet-[12px]">{val === "" ? "--" : val}</span>;
    };

    const row = [
      { name: getParam(ref), id: id, root: "Terminal ID" },
      { name: getParam(entity), id: id, root: "Name" },
      { name: getParam(type), id: id, root: "Type" },
      { name: getParam(provider), id: id, root: "Last Connection" },
      { name: getParam(amount), id: id, root: "Mount" },
      { name: getParam(date), id: id, root: "Date" },
      { name: getParam(status), id: id, root: "Status" },
    ];

    return row;
  };
  const viewRow = (id) => {
    setRowId(id);
    const item = transactions?.data?.find((item) => item.id === id);
    setEntity({
      id: item?.id,
      name: item?.type,
      descrption: { label: "RRN", value: item?.rrn },
      type: "text-type",
      hasActions: false,
      activate: () => {},
      deactivate: () => {},
    });
    showTransaction();
  };

  const switchPage = async (page) => {
    const res = await service.getTransactionPages(page, 10);
    // console.log(res.data);
    if (res.message === "success") {
      setTransactions(res.data);
    }
    return { message: res.message };
  };

  const data = transactions
    ? transactions?.data?.map((t) =>
        createdata(
          t?.id,
          t?.transaction_reference,
          "",
          t.type,
          t?.provider?.name,
          toPrice(t?.amount),
          t?.created_at?.slice(0, 10),
          t.status
        )
      )
    : [];
  return (
    <Table
      checkedList={checkedList}
      append={append}
      remove={remove}
      data={data}
      header={header}
      viewRow={viewRow}
      paginationData={{
        limit: transactions?.limit,
        page: transactions?.page,
        total: transactions?.total,
        "more-pages": transactions["more-pages"],
        navigatePage: switchPage,
      }}
      deleteParam={{
        title: "Transactions",
        label:
          "You are about to delete a transaction. Please click confirm to delete the transaction",
      }}
    />
  );
};

export default TransactionTable;

export const banks = [
  {
    id: 1,
    name: "Access Bank",
    code: "044",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 2,
    name: "Citibank",
    code: "023",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 3,
    name: "Diamond Bank",
    code: "063",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 4,
    name: "Ecobank Nigeria",
    code: "050",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 5,
    name: "Fidelity Bank Nigeria",
    code: "070",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 6,
    name: "First Bank of Nigeria",
    code: "011",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 7,
    name: "First City Monument Bank",
    code: "214",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 8,
    name: "Guaranty Trust Bank",
    code: "058",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 9,
    name: "Heritage Bank Plc",
    code: "030",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 10,
    name: "Keystone Bank Limited",
    code: "082",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 11,
    name: "Polaris Bank",
    code: "076",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 12,
    name: "Stanbic IBTC Bank Nigeria Limited",
    code: "221",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 13,
    name: "Standard Chartered Bank",
    code: "068",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 14,
    name: "Sterling Bank",
    code: "232",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 15,
    name: "Union Bank of Nigeria",
    code: "032",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 16,
    name: "United Bank for Africa",
    code: "033",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 17,
    name: "Unity Bank Plc",
    code: "215",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 18,
    name: "Wema Bank",
    code: "035",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
  {
    id: 19,
    name: "Zenith Bank",
    code: "057",
    created_at: "2023-12-23T07:22:03.000000Z",
    updated_at: "2023-12-23T07:22:03.000000Z",
  },
];

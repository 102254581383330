import React, { useEffect, useRef, useState } from "react";
import { useDataContext } from "../../../State/context/DataContext";
import { useFlowContext } from "../../../State/context/FlowContext";
import Table from "./Table";
// import { AiOutlineCheck } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import Toggle from "../Utitls/Toggle/Toggle";
import { providerService } from "../../../Services/providers.service";
import { toast } from "react-hot-toast";

const header = [
  { name: "Provider Name", ratio: 20 },
  { name: "Status", ratio: 60 },
  { name: "Activate / Deactivate", ratio: 20 },
];

const CustomCell = ({ val, className }) => {
  return <span className={className}>{val}</span>;
};
const getColorCode = (val) => {
  if (val === "Nibbs")
    return <CustomCell val={val} className={" uppercase text-[#7EA01A]"} />;
  else if (val === "Interswitch")
    return <CustomCell val={val} className={"capitalize text-[#130F40] "} />;
  else return <CustomCell val={val} className={" uppercase text-[#3F5DFF] "} />;
};

const DropDown = ({
  current,
  dropDown,
  setDropDown,
  targetId,
  setTargetId,
}) => {
  const [delay, setDelay] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (dropDown && !delay)
      setTimeout(() => {
        setDelay(true);
      }, 30);
    else if (delay && !dropDown) setDelay(false);
  }, [dropDown, delay]);

  return (
    <div className="flex flex-col ralative ">
      <span
        ref={ref}
        onClick={() => {
          targetId === current.id ? setDropDown(!dropDown) : setDropDown(true);
          setTargetId(current.id);
        }}
        className="flex items-center cursor-pointer gap-[5px]"
      >
        {current.val} <BiChevronDown />
      </span>
      {/* {dropDown && targetId === current.id && (
        <div
          className={`flex flex-col z-[9999] min-w-[130px] rounded-[8px] w-[130px] top-[20px] left-0 bg-[#fff] absolute  p-[5px] border border-[#E6E6E6] transition-all ease-in-out duration-100 ${
            delay ? "translate-y-0" : "translate-y-[-20px]"
          }`}
        >
          {[
            { name: "NIBSS", id: 1 },
            { name: "Interswitch", id: 2 },
            { name: "UPSL", id: 3 },
          ].map((p) => (
            <span
              onClick={() => {
                setTargetId(current.id);
                setDropDown(false);
              }}
              className={`flex items-center cursor-pointer gap-[10px] p-[5px] ${
                current.val.props.val === p.name && "bg-[#4934d441]"
              }`}
            >
              <AiOutlineCheck
                className={`min-w-[8px] text-[#4834D4] text-[8px] ${
                  current.val.props.val === p.name ? "opacity-1" : "opacity-0"
                }`}
              />
              {getColorCode(p.name)}
            </span>
          ))}
        </div>
      )} */}
    </div>
  );
};

const ServiceTable = ({ showProvider }) => {
  const { providers } = useDataContext();
  const { setRowId } = useFlowContext();
  const service = new providerService();
  const [checkedList, setCheckedList] = useState([]);
  const [targetId, setTargetId] = useState("");
  const [dropDown, setDropDown] = useState(false);
  const append = (id) => setCheckedList([...checkedList, id]);
  const remove = (id) =>
    setCheckedList([...checkedList.filter((c) => c === id)]);
  //   console.log(targetId);
  const viewRow = (id) => {
    setRowId(id);
    showProvider();
  };

  const switchProvider = async (id) => {
    console.log(id);
    const res = await service.switchProvider(id);
    if (res.message === "success") {
      toast.success("Privider has been switched successfully");
    } else {
      toast.error("Failed to switch");
    }
  };

  const createdata = (provider, status, id) => {
    // console.log(provider);

    const getParam = (val, id) => {
      console.log(id);
      if (val === 1 || val === 0) {
        console.log(val);
        return <Toggle status={val} activate={switchProvider} id={id} />;
      } else if (val === "Nibbs" || val === "Interswitch" || val === "UPSL") {
        return (
          <div className=" relative">
            <DropDown
              dropDown={dropDown}
              setDropDown={setDropDown}
              current={{ val: getColorCode(val), id: id }}
              id={id}
              targetId={targetId}
              setTargetId={setTargetId}
            />
          </div>
        );
      } else {
        return val;
      }
    };

    const row = [
      { name: getParam(provider, id), id: id, root: "Provider Name" },
      {
        name: getParam(status === 0 ? "Inactive" : "active", id),
        id: id,
        root: "Status",
      },
      { name: getParam(status, id), id: id, root: "Activate / Deactivate" },
    ];

    return row;
  };

  const data = providers
    ? providers?.data?.map((t) => createdata(t.name, t.active, t.id))
    : [];
  // console.log(data);
  return (
    <Table
      checkedList={checkedList}
      append={append}
      remove={remove}
      data={data}
      header={header}
      viewRow={viewRow}
      fullwidth={true}
      hidePagination={true}
    />
  );
};

export default ServiceTable;

import  { useEffect, useState } from "react";
import { authService } from "../Services/authService.service";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import authService from "../Services/authService.service";

const useAuthentication = () => {
  const { login, forgotPassword } = new authService();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [passwords, setPasswords] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({
    new_password: "",
    confirm_password: "",
  });

  const [fgEmail, setFgEmail] = useState("");
  const [fgEmailError, setFgEmailError] = useState("");

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [terms, setTerms] = useState(true);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validateLoginForm = () => {
    const { email, password } = form;
    const errs = {};
    if (email === "") errs.email = "Required";
    else if (!validateEmail(email)) errs.email = "Invalid email address";
    if (password === "") errs.password = "Required";
    return errs;
  };

  const strongPassword = (password) => {
    const strength = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );
    return strength.test(password);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "email" && !validateEmail(value))
      setErrors({ ...errors, [name]: "Invalid email address*" });
    else setErrors({ ...errors, [name]: "" });
  };

  const handleEmailChange = (e) => {
    setFgEmail(e.target.value);
    if (e.target.value === "") setFgEmailError("Required*");
    else if (!validateEmail(e.target.value))
      setFgEmailError("Invalid email address*");
    else setFgEmailError("");
  };

  const handlePasswordsChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
    if (value === "")
      setPasswordErrors({ ...passwordErrors, [name]: "Required*" });
    else if (name === "new_password" && !strongPassword(value))
      setPasswordErrors({ ...passwordErrors, [name]: "Password is weak*" });
    else if (name === "confirm_password" && value !== passwords.new_password)
      setPasswordErrors({
        ...passwordErrors,
        [name]: "Passwords do not match*",
      });
    else setPasswordErrors({ ...passwordErrors, [name]: "" });
  };

  const singupLayoutLabels = {
    title: "Sign up on Middleware",
    subtitle: "Sign up on Middleware",
  };
  const loginLayoutLabels = {
    title: "Login",
    subtitle: "Login to get the best experience",
  };

  const resetLayoutLabels = {
    title: "Let's get you started.",
    subtitle: `As a first-time user, an email has been sent to ${localStorage?.newUser} to reset your password. \n Kindly follow the instructions to setup a new secured password.`,
  };

  const fgPasswordLayoutLabels = {
    title: "Forgot password?",
    subtitle: "Enter the email address registered with your account",
  };

  const handleSignup = async () => {};

  const handleLogin = async () => {
    if (Object.keys(validateLoginForm()).length > 0) {
      setErrors({ ...validateLoginForm() });
    } else {
      setLoading(true);
      const res = await login(form);
      // console.log(form);
      setLoading(false);
      // if (res.message === "success") navigate("/dashboard");
      const requirePasswordChange =
        form.email.toLowerCase() === form.password.toLowerCase();
      if (res.message === "success" && requirePasswordChange) {
        const fg_res = await forgotPassword(form.email);
        if (fg_res.message === "success") {
          localStorage.setItem("newUser", form.email);
          toast.success("Welcome to Airvend Middleware.");
          navigate("/new-password");
        } else {
          // toast.error("Something went wrong. Please try agian.");
        }
      } else if (res.message === "success" && !requirePasswordChange)
        navigate("/dashboard");
      else return;
    }
  };

  const handleForgotPassword = async () => {
    if (fgEmail === "") {
      setFgEmailError("Required*");
    } else if (!validateEmail(fgEmail)) {
      setFgEmailError("Invalid email*");
    } else {
      setLoading(true);
      const res = await forgotPassword(fgEmail);
      if (res.message === "success") {
        toast.success("Reset OTP has been sent to email");
        navigate("/verify-user");
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // const new
  // const handleNewPassword = () => {};

  useEffect(() => {
    if (!Object.values(form).includes("")) setActive(true);
    else setActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return {
    form,
    fgEmail,
    errors,
    fgEmailError,
    loading,
    singupLayoutLabels,
    loginLayoutLabels,
    fgPasswordLayoutLabels,
    resetLayoutLabels,
    active,
    terms,
    passwordErrors,
    passwords,
    setTerms,
    handleSignup,
    handleChange,
    handleLogin,
    handleEmailChange,
    handleForgotPassword,
    handlePasswordsChange,
  };
};

export default useAuthentication;

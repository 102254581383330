import React from "react";
import Icon from "../../../Constants/icons";

const ActiveOverViewCards = ({ image, title, rating, isLast }) => {
  const getRating = () => {
    if (rating === 1) return [1, 0, 0, 0, 0];
    else if (rating === 2) return [1, 1, 0, 0, 0];
    else if (rating === 3) return [1, 1, 1, 0, 0];
    else if (rating === 4) return [1, 1, 1, 1, 0];
    else return [1, 1, 1, 1, 1];
  };

  return (
    <div
      className={`flex items-center w-full justify-between ${
        !isLast && "border-b pb-[12px]"
      }`}
    >
      <div className="flex items-center gap-[12px]">
        <span className="w-[35px] h-[35px] rounded-full bg-gray-50">
          {image}
        </span>
        <span className="text-[14px] w-full whitespace-nowrap max-w-[120px] truncate">
          {title}
        </span>
      </div>
      <div className="flex items-center">
        {getRating().map((a, n) => {
          return <Icon key={n} name={a === 0 ? "star" : "active_star"} />;
        })}
      </div>
    </div>
  );
};

export default ActiveOverViewCards;

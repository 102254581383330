import useClient from "../Helpers/axiosInstance";
import { toast } from "react-hot-toast";
import { useDataContext } from "../State/context/DataContext";

export class merchantService {
  dataContext = useDataContext();
  client = useClient();
  filterMerchantById = (id) => {
    const merchants = this.dataContext.merchants;
    const merchant = merchants.data.filter((u) => u.id === id);
    return merchant[0];
  };
  createMerchant = async (data) => {
    try {
      await this.client.post("/merchants", data);
      this.fetchMerchants();
      toast.success("Merchant Created");
      return { message: "success" };
    } catch (err) {
      return { message: "failed" };
    }
  };
  fetchMerchants = async () => {
    try {
      const merchants = await this.client.get("/merchants");
      this.dataContext.setMerchants(merchants.data);
      return { message: "success" };
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        toast.error("Invalid email or password");
      } else toast.error("Something went wrong! Please try again.");
      return { message: "failed" };
    }
  };
  getMerchantPages = async (page, limit) => {
    try {
      const res = await this.client.get(
        `/merchants?page=${parseInt(page)}&limit=${parseInt(limit)}`
      );
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  searchMerchant = async (search) => {
    try {
      const res = await this.client.get(`/merchants?query=${search}`);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  filterMerchant = async (fromDate, toDate) => {
    try {
      const res = await this.client.get(
        `/merchants?from=${fromDate}&to=${toDate}`
      );
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  fetchBanks = async () => {
    try {
      const res = await this.client.get(`/banks`);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  updateMerchant = async (data) => {
    try {
      await this.client.put("/merchants", data);
      this.fetchMerchants();
      return { message: "success" };
    } catch (err) {
      return { message: "failed" };
    }
  };
  downloadBlob(blob, name = "file.txt") {
    // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");

    // Set link's href to point to the Blob URL
    link.href = blobUrl;
    link.download = name;

    // Append link to the body
    document.body.appendChild(link);

    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    // Remove link from body
    document.body.removeChild(link);
  }
  download = async (data) => {
    try {
      const res = await this.client.get("/merchants/download");

      // console.log(res);
      const blob = new Blob([res.data], { type: "application/zip" });

      // Create a temporary URL for the Blob object

      console.log("blob", blob);
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement("a");

      // Set the href attribute of the link to the temporary URL
      link.href = url;

      // Set the download attribute to specify the filename
      link.download = "merchant-doc.zip";

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger a click event on the link to start the download
      link.click();
      console.log("download link", link);

      // Remove the link from the body
      document.body.removeChild(link);

      // Revoke the temporary URL to free up memory
      URL.revokeObjectURL(url);
      return { message: "success" };
    } catch (err) {
      return { message: "failed" };
    }
  };
  bulkUpload = async (data) => {
    try {
      const res = await this.client.post("/merchants/upload", data);
      // this.fetchMerchants();
      return { message: "success", data: res.data };
    } catch (err) {
      // console.log(err.response.status);
      if (err.response.status === 422)
        return { message: "failed", response: "Unable to process document" };
      else
        return {
          message: "failed",
          response: "Something went wrong. Please try again.",
        };
    }
  };
}

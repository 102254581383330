import { useState } from "react";

const useGetStarted = () => {
  const [signup, setSignup] = useState({
    first_name: "",
    last_name: "",
    role: "",
  });
  const [signupErrors, setSignupErrors] = useState({
    first_name: "",
    last_name: "",
    role: "",
  });

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const handleSignUpChange = (e) => {
    const { name, value } = e.target;
    setSignup({ ...signup, [name]: value });
    if (value === "") setSignupErrors({ ...signupErrors, [name]: "Required*" });
    else setSignupErrors({ ...signupErrors, [name]: "" });
  };
  const handleSingupSelect = (value) => {
    setSignup({ ...signup, role: value });
  };

  return {
    signup,
    signupErrors,
    loading,
    active,
    setLoading,
    setActive,
    handleSignUpChange,
    handleSingupSelect,
  };
};

export default useGetStarted;

import React from "react";

const CreateEntityLayout = ({ title, children, vector }) => {
  return (
    <div className="flex items-center px-5 py-[50px] justify-center">
      <div className="w-[902px] transition-all duration-300 ease-in-out min-h-[595px] p-[29px] border rounded-[8px] grid grid-cols-12">
        <div className="col-span-6 bg-[--shade] pt-[100px] flex justify-center ">
          <img className="w-[220px] h-[303px]" src={vector} alt="" />
        </div>
        <div className="col-span-6 px-[20px] flex flex-col gap-[29px]">
          <span className="text-[--dark] h-[77px] w-full bg-[--shade] flex items-center justify-center rounded-[8px]">
            {title}
          </span>
          <div className="flex flex-col gap-[16px]">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CreateEntityLayout;

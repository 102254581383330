// import React from "react";
// import { transactionService } from "../Services/transaction.service";
import { useDataContext } from "../State/context/DataContext";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-hot-toast";
import useClient from "../Helpers/axiosInstance";
import toast from "react-hot-toast";

const useDashboard = () => {
  // const { fetchTransactions } = new transactionService();
  const navigate = useNavigate();
  const client = useClient();
  const {
    setMerchants,
    setTransactions,
    setUsers,
    setProviders,
    setTerminals,
    setRoles,
    setPermissions,
    roles,
    setBanks,
    setStats,
  } = useDataContext();
  const getDashBoardData = async () => {
    // console.log();
    await client
      .get("/transactions")
      .then((transactions) => {
        // console.log("hbcujinmcki", transactions);
        setTransactions(transactions.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 401) {
          navigate("/login");
          toast.error("Session expired");
        }
      });
    await client
      .get("/merchants")
      .then((merchants) => setMerchants(merchants.data))
      .catch((err) => {});
    await client
      .get("/users")
      .then((users) => setUsers(users.data))
      .catch((err) => {});
    await client
      .get("/dashboard")
      .then((stat) => setStats(stat.data))
      .catch((err) => {});
    await client
      .get("/providers")
      .then((providers) => setProviders(providers.data))
      .catch((err) => {});
    await client
      .get("/terminals")
      .then((terminals) => setTerminals(terminals.data))
      .catch((err) => {});
    await client
      .get("/roles")
      .then((roles_) => {
        // console.log("roles", roles);
        setRoles({ ...roles, data: roles_.data });
      })
      .catch((err) => {});
    await client
      .get("/roles/create")
      .then((permissions) => setPermissions(permissions.data))
      .catch((err) => console.log(err));
    await client
      .get("/banks")
      .then((banks) => {
        console.log("banks", banks);
        setBanks(banks.data);
      })
      .catch((err) => console.log(err));
  };
  return { getDashBoardData };
};

export default useDashboard;

import React from "react";
import ActiveOverViewCards from "../../../UI/Cards/ActiveOverViewCards";
// import images from "../../../../Constants/images";
import Analyst from "../../../UI/Layouts/Analyst";
import { useDataContext } from "../../../../State/context/DataContext";

const ActiveMerchants = () => {
  const { merchants } = useDataContext();
  // console.log(merchants);
  return (
    <Analyst title={"Most Active Merchants of the week"}>
      {[...merchants?.data].slice(0, 5).map((m, n) => {
        const name = m.name.split(" ");
        return (
          <ActiveOverViewCards
            key={n}
            isLast={n + 1 === 5}
            rating={4}
            image={
              <span className="bg-[#80808019] text-[14px] w-[35px] h-[35px] flex items-center justify-center rounded-full">
                {name[0][0]}
                {name[1][0]}
              </span>
            }
            title={m.name}
          />
        );
      })}
      {/* <div className="flex justify-center items-center w-full">
        <span>No Data Yet </span>
      </div> */}
    </Analyst>
  );
};

export default ActiveMerchants;

import React, { useEffect, useState } from "react";
// import Icon from "../../../Constants/icons";
// import { useParams } from "react-router-dom";
// import { useFlowContext } from "../../../State/context/FlowContext";

const Topbar = () => {
  const page = window.location.href.split("/");
  const pageName = page[page.length - 1]
    .replace("-", " ")
    .replace("&", " and ");
  const [page_, setPage] = useState("Overview");
  const userData = localStorage.middleware
    ? JSON.parse(window.atob(localStorage.middleware))
    : {first_name: "", last_name: ""};
  // console.log(userData);
  useEffect(() => {
    setPage(pageName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[page]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex flex-col">
        <span className="text-[24px] capitalize">
          {page_.replace("-", " ")}
        </span>
        <span className="text-[16px] text-[#999]">
          Here’s an overview of your {page_.replace("-", " ")}
        </span>
      </div>
      <div className="flex items-center gap-[40px]">
        {/* <div className="flex relative">
          <Icon name={"bell"} className={"w-[30px] h-[30px]"} />
          <span className="bg-[--dark] px-[5px] text-[#fff] absolute right-[-10px] top-[-5px] text-[12px] flex justify-center items-center h-[21px] rounded-[24px]">
            30
          </span>
        </div> */}
        <div className="flex items-center gap-[12px]">
          <span className="w-[43px] h-[43px] flex justify-center rounded-full items-center bg-[#F8F8F8]">
            {userData?.first_name[0]} {userData?.last_name[0]}
          </span>
          <div className="flex flex-col">
            <span className="text-[14px]">
              {userData?.first_name} {userData?.last_name[0]}
            </span>
            <span className="text-[12px] text-[#999]">
              {userData?.username}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;

import React, { useEffect } from "react";
import images from "../../../Constants/images";

const AuthLayout2 = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full min-h-screen h-[] flex flex-col justify-center items-center px-[29px] py-[65px] relative">
      <span className="absolute left-0 top-0 px-[29px] pt-[65px]">
        {images.logo}
      </span>
      {children}
    </div>
  );
};

export default AuthLayout2;

import React from "react";
import images from "../../../Constants/images";
import SidebarCard from "../../UI/Utitls/SidebarCard";

const Sidebar = () => {
  return (
    <div className="p-[20px] flex w-full h-full items-center justify-center min-w-[300px] ">
      <div className="bg-[rgba(72,52,212,0.03)] rounded-[10px] shadow-purple-200 py-[20px] h-full w-full flex flex-col gap-[24px]">
        <div className="px-[20px]">{images.logo}</div>
        <div className="flex flex-col justify-between min-h-[450px] h-full">
          <div className="flex flex-col h-full max-h-[430px] overflow-y-auto gap-2">
            <SidebarCard
              icon={"dashboard"}
              title={"Dashboard"}
              path={"/dashboard"}
            />
            <SidebarCard
              icon={"transaction"}
              title={"Transactions"}
              path={"/dashboard/transactions"}
            />
            <SidebarCard
              icon={"user_management"}
              title={"User Management"}
              path={"/dashboard/user-managment"}
              children_={[
                {
                  icon: "create_user",
                  title: "Create User",
                  path: "/dashboard/user-management/create-user",
                },
                {
                  icon: "user_mng",
                  title: "Manage User",
                  path: "/dashboard/user-management/manage-user",
                },
              ]}
            />
            <SidebarCard
              icon={"merchant"}
              title={"Merchants"}
              path={"/dashboard/merchants"}
            />
            <SidebarCard
              icon={"provider"}
              title={"Service Management"}
              path={"/dashboard/service-management"}
            />
            <SidebarCard
              icon={"terminal"}
              title={"Terminals"}
              path={"/dashboard/terminals"}
            />
            <SidebarCard
              icon={"report"}
              title={"Report and Analysis"}
              path={"/dashboard/report&analysis"}
            />
            <SidebarCard
              icon={"role"}
              title={"Role Management"}
              path={"/dashboard/role-management"}
            />
          </div>
          <SidebarCard
            icon={"logout"}
            title={"Log Out"}
            path={"/login"}
            action={() => localStorage.clear()}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

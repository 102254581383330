import React from "react";
import Revenue from "../UI/Cards/Revenue";
import AreaChart from "../Components/Charts/AreaChart";
import BarChart from "../Components/Charts/BarChart";
import ActiveExchange from "../Components/Sections/Report/ActiveExchange";
import RiskAnalysis from "../Components/Sections/Report/RiskAnalysis";
import { useDataContext } from "../../State/context/DataContext";

const Report = () => {
  const { stats } = useDataContext();
  // console.log(stats?.totalVolume);
  const toPrice = ( amount) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "NGN",
    });
    return formatter.format(amount).replace("NGN", "");
  };
  return (
    <div className="w-full flex flex-col gap-[25px]">
      <div className="grid grid-cols-12 gap-[30px]">
        <Revenue
          label={"Total Transaction"}
          amount={toPrice(stats?.totalVolume ? stats?.totalVolume : 0)}
          rate={<span className="text-[#59B168]">+2.15%</span>}
          className={"border-y border-b-[--dark]"}
          current={true}
          hasborder={true}
        />

        <Revenue
          label={"Revenue"}
          amount={toPrice(stats?.inflowVolume ? stats?.inflowVolume : 0)}
          rate={<span className="text-[#59B168]">+2.15%</span>}
          className={"border-y"}
          current={true}
          hasborder={true}
        />
        {/*  */}
        <Revenue
          label={"Revenue"}
          amount={toPrice(stats?.outflowVolume ? stats?.outflowVolume : 0)}
          rate={<span className="text-[#F5222D]">-2.15%</span>}
          className={"border-y"}
          current={true}
          hasborder={true}
        />
      </div>
      <div className="w-full grid grid-cols-12 gap-[21px] h-[377px]">
        <div className="col-span-8 border h-[388px] rounded-[8px]">
          <AreaChart />
        </div>
        <div className="col-span-4 border rounded-[8px] h-[388px] overflow-y-auto">
          <ActiveExchange />
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-[21px] h-[377px]">
        <div className="col-span-8 border h-[388px] rounded-[8px]">
          <BarChart />
        </div>
        <div className="col-span-4 border rounded-[8px] h-[388px] overflow-y-auto">
          <RiskAnalysis />
        </div>
      </div>
    </div>
  );
};

export default Report;

import React, { useRef, useState } from "react";
import Search from "./Search";
import CalenderFilter from "./CalenderFilter";
// import Filter from "./Filter";
import { ButtonFill } from "./Button";
import { SlOptionsVertical } from "react-icons/sl";
import { BsFillTriangleFill } from "react-icons/bs";
import Icon from "../../../Constants/icons";
import { AiOutlinePlus, AiOutlineUpload } from "react-icons/ai";
import { useFlowContext } from "../../../State/context/FlowContext";
// import { toast } from "react-hot-toast";
import Upload from "../Modals/Upload";

const Toolbar = ({ allowed, toolControllers }) => {
  // const route = window.location.href;
  const { setCreateScreen, setRowId } = useFlowContext();
  const [options, setOptions] = useState(false);
  const [upload, setUpload] = useState(false);
  // const [loading, setLoading] = useState(false);
  const modalRef = useRef();
  const optRef = useRef();
  const handleDownload = async () => {
    toolControllers.download();
  };

  const handleUpload = async (file) => {
    toolControllers.upload(file);
    // console.log(file)
  };
  window.addEventListener("click", (e) => {
    if (
      upload &&
      !modalRef.current?.contains(e.target) &&
      !optRef.current?.contains(e.target)
    ) {
      setUpload(false);
    }
  });
  window.addEventListener("click", (e) => {
    if (options && !optRef.current?.contains(e.target)) {
      setOptions(false);
    }
  });
  return (
    <div className="flex w-full h-[44px] justify-between items-center">
      {allowed?.includes("search") ? (
        <Search handleSearch={toolControllers?.handleSearch} />
      ) : (
        <div></div>
      )}
      <div className="flex gap-[16px]">
        {allowed?.includes("filter") ? (
          <CalenderFilter handleFilter={toolControllers?.handleFilter} />
        ) : (
          <div></div>
        )}
        {/* <Filter /> */}
        {allowed?.includes("download") && (
          <ButtonFill
            label={
              <span className="flex items-center gap-[8px]">
                <Icon name={"cloud"} className={"w-[24px] h-[24px]"} /> Export
              </span>
            }
            loading={toolControllers.downloading}
            action={handleDownload}
            active={true}
            classes={"h-[44px] p-[10px] max-w-[110px] text-[#fff]"}
          />
        )}
        {allowed?.includes("more") ? (
          <div ref={optRef} className="relative ">
            <span
              onClick={() => setOptions(!options)}
              className="h-[44px] w-[20px] flex justify-center items-center cursor-pointer"
            >
              <SlOptionsVertical className=" text-[20px] text-[#333]" />
            </span>
            {options && (
              <BsFillTriangleFill className="text-[#f5f5f5] absolute right-0 top-[40px] s" />
            )}
            {options && (
              <div className="absolute flex flex-col right-[-20px] w-[170px] shadow-md top-[52px] rounded-[10px] bg-[#f5f5f5] p-[10px]">
                {allowed?.includes("enable-create") && (
                  <span
                    onClick={() => {
                      setCreateScreen(true);
                      setOptions(false);
                      setRowId("");
                    }}
                    className="flex p-[10px] whitespace-nowrap cursor-pointer transition-all all duration-100 items-center gap-[10px] text-[13px] hover:bg-[--shade]"
                  >
                    <AiOutlinePlus /> Create New
                  </span>
                )}
                <span
                  onClick={() => setUpload(!upload)}
                  className="flex p-[10px] whitespace-nowrap cursor-pointer transition-all all duration-100 items-center gap-[10px] text-[13px] hover:bg-[--shade]"
                >
                  <AiOutlineUpload /> Bulk Upload
                </span>
              </div>
            )}
          </div>
        ) : null}
      </div>
      {upload && (
        <div className="fixed z-[999999] w-screen h-screen left-0  bottom-0 top-0 right-0 bg-[#00000020] flex items-center justify-center">
          <div ref={modalRef} className="w-fit h-fit">
            <Upload upload={handleUpload} loading={toolControllers.uploading} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Toolbar;

import React from "react";
import { useFlowContext } from "../../../State/context/FlowContext";
import { ButtonFillCustom } from "../Utitls/Button";
import useDelete from "../../../Hooks/useDelete";

const Delete = () => {
  const { deleteParams } = useFlowContext();
  const { loading, cancel, deleteItem } = useDelete();
  return (
    <div className="w-[436px] min-h-[197px] bg-[#fff] z-[999999999] p-[30px] rounded-[8px] flex flex-col">
      <span className="text-[16px] text-[#333] pb-[18px]">
        Delete {deleteParams?.title}
      </span>
      <span className="text-[12px] text-[#999]"> {deleteParams?.label}</span>
      <div className="flex justify-end w-full gap-[13px] mt-[35px]">
        <ButtonFillCustom
          label={"Cancel"}
          classes={
            "text-[--gray_text] p-[10px] text-[14px] rounded-[10px] font-[300]"
          }
          action={cancel}
          loading={false}
        />
        <ButtonFillCustom
          label={"Delete"}
          classes={
            "text-[#fff] bg-[#F5222D] p-[10px] text-[14px] rounded-[10px] font-[300]"
          }
          action={deleteItem}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default Delete;

import React from "react";
import AuthLayout2 from "../UI/Layouts/AuthLayout2";
import AuthForm from "../UI/Forms/AuthForm";
import useAuthentication from "../../Hooks/useAuthentication";
import { Link } from "react-router-dom";
import { Input } from "../UI/Utitls/Input";
import { BiArrowBack } from "react-icons/bi";

const ForgotPassword = () => {
  const {
    handleForgotPassword,
    loading,
    active,
    fgPasswordLayoutLabels,
    fgEmail,
    fgEmailError,
    handleEmailChange,
  } = useAuthentication();
  return (
    <AuthLayout2>
      <AuthForm
        handleSubmit={handleForgotPassword}
        loading={loading}
        active={active}
        labels={{
          title: fgPasswordLayoutLabels.title,
          subtitle: fgPasswordLayoutLabels.subtitle,
        }}
        btn={"Reset password"}
        centered={true}
        base_link={
          <Link
            to={"/login"}
            className="flex items-center text-[#666666] gap-[9px] cursor-pointer w-fit"
          >
            <BiArrowBack /> Back to login
          </Link>
        }
      >
        <div className="w-full">
          <Input
            label={"Enter your email address"}
            value={fgEmail}
            handleChange={handleEmailChange}
            name={"email"}
            error={fgEmailError}
          />
        </div>
      </AuthForm>
    </AuthLayout2>
  );
};

export default ForgotPassword;

import React from "react";
import AppLayout from "../UI/Layouts/AppLayout";
import { Outlet } from "react-router-dom";
// import useDashboard from "../../Hooks/useDashboard";

const Dashboard = () => {
  // const { getDashBoardData } = useDashboard();
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default Dashboard;

import React from "react";
import vector from "../../Assets/vectors/user_vector.png";
import {
  Input,
  // PasswordInput,
  Select,
} from "../UI/Utitls/Input";
import useCreateUser from "../../Hooks/useCreateUser";
import CreateEntityLayout from "../UI/Layouts/CreateEntityLayout";
import { ButtonFill } from "../UI/Utitls/Button";
import { IoIosClose } from "react-icons/io";

const CreateUser = () => {
  const {
    form,
    errors,
    loading,
    userRoles,
    userPermissions,
    permissions,
    handleChange,
    handleSubmit,
    handleSelect,
    handlePermission,
    removePermission,
    handleSelectBank,
    banks,
  } = useCreateUser();
  // console.log(userRoles);
  return (
    <CreateEntityLayout vector={vector} title={"Create New User"}>
      <Input
        label={"Full Name"}
        value={form.full_name}
        handleChange={handleChange}
        name={"full_name"}
        error={errors.full_name}
      />
      <Input
        label={"Enter your email address"}
        value={form.email}
        handleChange={handleChange}
        name={"email"}
        error={errors.email}
      />
      <Select
        label={"Enter User’s Role"}
        value={form?.role?.name}
        handleChange={handleSelect}
        name={"role"}
        error={errors.role}
        options={userRoles}
      />
      {(form?.role?.name?.toLowerCase() === "bank" ||
        form?.role?.name?.toLowerCase() === "merchant") && (
        <>
          <Select
            label={"Selenk Bank"}
            value={form.bank.name}
            handleChange={handleSelectBank}
            name={"bank"}
            error={errors.bank}
            options={banks}
          />
          {form?.role?.name?.toLowerCase() === "merchant" && (
            <Input
              label={"Account Number"}
              value={form.account_number}
              handleChange={handleChange}
              name={"account_number"}
              error={errors.account_number}
            />
          )}
        </>
      )}
      <Select
        label={"Select User’s Permissions"}
        value={form?.permission?.name}
        handleChange={handlePermission}
        name={"Permissions"}
        error={errors.permission}
        options={permissions}
      />
      {userPermissions?.length > 0 && (
        <div className="flex gap-x-3 flex-wrap gap-y-2 bg-[--shade] h-fit w-full p-3 mt-[-16px] ">
          {userPermissions?.map((p, n) => (
            <span
              key={n}
              className="flex gap-2 capitalize items-center px-2 p-1 text-[12px] bg-[#8080803b] "
            >
              {p?.name?.replaceAll("-", " ")}
              <IoIosClose
                onClick={() => removePermission(p)}
                className=" cursor-pointer"
              />
            </span>
          ))}
        </div>
      )}
      <ButtonFill
        label={"Create Account "}
        classes={"w-full h-[44px] text-[#fff]"}
        action={handleSubmit}
        loading={loading}
        active={true}
      />
    </CreateEntityLayout>
  );
};

export default CreateUser;

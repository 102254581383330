import React from "react";
import { ButtonFillCustom, ButtonOutline } from "../Utitls/Button";
import Icon from "../../../Constants/icons";
import { useFlowContext } from "../../../State/context/FlowContext";

const ViewEntityLayout = ({
  setShowDetail,
  setRowId,
  // detail,
  // hasControl,
  children,
}) => {
  const { entity } = useFlowContext();
  // console.log("entity", entity);
  return (
    <div className="flex flex-col mb-[43px]">
      <div
        onClick={() => {
          setRowId("");
          setShowDetail(false);
        }}
        className="flex items-center gap-[8px] text-[12px] cursor-pointer"
      >
        <Icon name={"back"} className={"w-[18px]"} />
        Back
      </div>
      <div className="flex items-center justify-between my-[43px]">
        <div className="flex flex-col gap-5">
          <span className="bg-[--shade] font-[500] w-[96px] h-[96px] rounded-full flex items-center justify-center">
            {entity?.name}
          </span>
          {entity?.descrption?.label !== "" && (
            <span>
              {entity?.descrption?.label} : {entity?.descrption?.value}
            </span>
          )}
        </div>
        {entity?.hasActions && (
          <div className="flex items-center gap-[15px]">
            <ButtonOutline
              label="Activate"
              classes={
                "h-[33px] w-[94px] border-[--dark] text-[--dark] text-[14px]"
              }
              action={entity?.activate}
              loading={false}
            />
            <ButtonFillCustom
              label="Deactivate"
              classes={
                "h-[33px] w-[94px] bg-[rgba(245,34,45,0.10);] text-[#F5222D] text-[14px]"
              }
              action={entity?.deactivate}
              loading={false}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

export default ViewEntityLayout;

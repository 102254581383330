import React from "react";
// import Toolbar from "../UI/Utitls/Toolbar";
import TransactionTable from "../UI/Tables/TransactionTable";
// import Table from "../UI/Tables/Table";
import EntityLayout from "../UI/Layouts/EntityLayout";
import Transaction from "../Components/Sections/Transactions/Transaction";
import { useDataContext } from "../../State/context/DataContext";
import { transactionService } from "../../Services/transaction.service";

const Transactions = () => {
  const { setTransactions } = useDataContext();
  const service = new transactionService();
  const searchTransactions = async (search) => {
    const res = await service.searchTransaction(search);
    // console.log(res.data);
    if (res.message === "success") {
      setTransactions(res.data);
    }
    return { message: res.message };
  };
  const filterTransactions = async (fromDate, toDate) => {
    const res = await service.filterTransaction(fromDate, toDate);
    // console.log(res.data);
    if (res.message === "success") {
      setTransactions(res.data);
    }
    return { message: res.message };
  };
  return (
    <EntityLayout
      detail={<Transaction />}
      allowedMenu={["search", "filter"]}
      CreateScreen={null}
      toolControllers={{
        handleSearch: searchTransactions,
        handleFilter: filterTransactions,
      }}
      MainContent={({ viewDetails }) => (
        <TransactionTable showTransaction={viewDetails} />
      )}
    />
  );
};

export default Transactions;

import React from "react";
import Analyst from "../../../UI/Layouts/Analyst";
import Donut from "../../Charts/Donut";

const RiskAnalysis = () => {
  return (
    <Analyst title={"Risk Analysis"}>
      <div className="flex justify-center items-center mb-[50px]">
        <Donut />
      </div>
      <div className="grid grid-cols-12 justify-between gap-[10px]">
        <div className="col-span-4 flex gap-[13px]">
          <span className="min-w-[26px] h-[5px] bg-[#DC2727] mt-[7px]"></span>
          <div className="flex flex-col">
            <span className="text-[#666] text-[12px]">High</span>
            <span className="text-[#11263C] text-[12px]">30%</span>
          </div>
        </div>
        <div className="col-span-4 flex gap-[13px]">
          <span className="min-w-[26px] h-[5px] bg-[#2C2A89] mt-[7px]"></span>
          <div className="flex flex-col">
            <span className="text-[#666] text-[12px]">Medium</span>
            <span className="text-[#11263C] text-[12px]">30%</span>
          </div>
        </div>
        <div className="col-span-4 flex gap-[13px]">
          <span className="min-w-[26px] h-[5px] bg-[#25B1BA] mt-[7px]"></span>
          <div className="flex flex-col">
            <span className="text-[#666] text-[12px]">Low</span>
            <span className="text-[#11263C] text-[12px]">30%</span>
          </div>
        </div>
      </div>
    </Analyst>
  );
};

export default RiskAnalysis;

import React from "react";
// import { useDataContext } from "../State/context/DataContext";
import { useFlowContext } from "../State/context/FlowContext";
import Delete from "../Views/UI/Modals/Delete";
import Upload from "../Views/UI/Modals/Upload";
import Role from "../Views/UI/Modals/Role";

const useModals = () => {
  const { activeModal, modalType } = useFlowContext();
  if (activeModal)
    return (
      <div className="bg-[rgba(0,0,0,0.30)] fixed top-0 bottom-0 left-0 right-0 w-screen min-h-screen z-[999999999] h-full flex justify-center items-center py-[100px]">
        {modalType === "delete" && <Delete />}
        {modalType === "upload" && <Upload />}
        {modalType === "role" && <Role />}
      </div>
    );
  else return null;
};

export default useModals;

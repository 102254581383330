import { useEffect, useState } from "react";
import { useDataContext } from "../State/context/DataContext";
import { useFlowContext } from "../State/context/FlowContext";
import { terminalService } from "../Services/terminals.service";
import { banks } from "../Assets/json/banks";
import { toast } from "react-hot-toast";

const useTerminal = () => {
  const {
    merchants,
    // terminals
  } = useDataContext();
  const { rowId, setCreateScreen } = useFlowContext();
  const {
    createTerminal,
    getTerminal,
    // updateTerminal
  } = new terminalService();
  const merchantNames = merchants.data.map((m) => m.name);
  // console.log(merchants);
  //   console.log()
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    tid: "",
    merchantName: "",
    type: "",
    operator: "",
    slipHeader: "",
    slipFooter: "",
    serialNumber: "",
    bankCode: {
      name: "",
      code: "",
    },
  });
  const [errors, setErrors] = useState({
    tid: "",
    merchantName: "",
    type: "",
    operator: "",
    slipHeader: "",
    slipFooter: "",
    serialNumber: "",
    bankCode: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "serialNumber" && value.length < 15)
      setErrors({ ...errors, [name]: "At least 15 characters*" });
    else setErrors({ ...errors, [name]: "" });
  };

  const handleSelect = (e, name) => {
    if (name === "bankCode")
      setForm({ ...form, [name]: { name: e.name, code: e.code } });
    else setForm({ ...form, [name]: e.name });
    setErrors({ ...errors, [name]: "" });
  };

  const getBankCode = (id) => {
    const code = banks.find((b) => b.id === id);
    if (code) return code;
    else return { name: "", code: "" };
  };

  const getTerminal_ = async () => {
    if (rowId && rowId !== "") {
      const res = await getTerminal(rowId);
      if (res.message === "success") {
        setForm({
          tid: res.data.data.tid,
          merchantName: merchants.data.find(
            (m) => m.id === res.data.data.merchant_id
          )?.name,
          type: res.data.data.type,
          operator: res.data.data.operator,
          slipHeader: res.data.data.slip_header,
          slipFooter: res.data.data.slip_footer,
          serialNumber: res.data.data.serial_number,
          bankCode: getBankCode(res.data.data.bank_id),
        });
      }
    }
  };
  const merchants_ = merchants.data;

  const validateForm = () => {
    const {
      tid,
      merchantName,
      type,
      operator,
      slipFooter,
      slipHeader,
      bankCode,
      serialNumber,
    } = form;
    const newErrors = {};
    if (tid === "") newErrors.tid = "Required*";
    if (merchantName === "") newErrors.merchantName = "Required*";
    if (type === "") newErrors.type = "Required*";
    if (operator === "") newErrors.operator = "Required*";
    if (slipFooter === "") newErrors.slipFooter = "Required*";
    if (slipHeader === "") newErrors.slipHeader = "Required*";
    if (serialNumber === "") newErrors.serialNumber = "Required*";
    else if (serialNumber.length < 15)
      newErrors.serialNumber = "At least 15 characters*";
    if (bankCode.code === "") newErrors.bankCode = "Required*";
    return newErrors;
  };

  const handleSubmit = async () => {
    if (Object.keys(validateForm()).length > 0) {
      setErrors({ ...errors, ...validateForm() });
    } else {
      setLoading(true);
      const res = await createTerminal({
        tid: form.tid,
        merchantName: form.merchantName,
        type: form.type,
        operator: form.operator,
        slipHeader: form.slipHeader,
        slipFooter: form.slipFooter,
        serialNumber: form.serialNumber,
        bankCode: form.bankCode.code,
      });
      if (res.message === "success") {
        toast.success("Terminal created successfully");
        setCreateScreen(false);
      } else {
        toast.error("Failed to created Terminal");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getTerminal_();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    form,
    errors,
    loading,
    merchantNames,
    merchants_,
    handleSelect,
    handleChange,
    handleSubmit,
    banks,
  };
};

export default useTerminal;

import { useEffect, useState } from "react";
import { userService } from "../Services/user.service";
import { useFlowContext } from "../State/context/FlowContext";
import { useDataContext } from "../State/context/DataContext";
import { banks } from "../Assets/json/banks";

const useUpdateUser = () => {
  // let id_ = id
  const { filterUsersById } = new userService();
  const { rowId } = useFlowContext();
  const {
    roles,
    //  permissions
  } = useDataContext();
  const userRoles = roles.data;

  const [form, setForm] = useState({
    role: { name: "", id: "" },
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    bank: { name: "", id: "" },
    account_number: "",
  });
  const [activity, setActivity] = useState({
    active: 0,
    last_seen: "2 mins ago",
    transaction: "N 2000.00",
    transaction_time: "2023-08-12, 04:30PM",
  });
  const [errors, setErrors] = useState({
    role: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    bank: "",
    account_number: "",
  });

  const getUser = async () => {
    const data = await filterUsersById(rowId);
    const bank = banks.find((b) => b.id === data?.bank_id);
    setForm({
      ...form,
      role: data?.roles[0],
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.username,
      phone: data?.phone,
      bank: bank ? bank : { name: "", id: "" },
      account_number: data?.account_number ? data?.account_number : "",
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "email" && !validateEmail(value))
      setErrors({ ...errors, [name]: "Invalid email address*" });
    else setErrors({ ...errors, [name]: "" });
  };

  const handleSelect = (e, name) => {
    setForm({ ...form, [name]: e });
    setErrors({ ...errors, [name]: "" });
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    form,
    errors,
    handleChange,
    activity,
    userRoles,
    banks,
    handleSelect,
    setActivity
  };
};

export default useUpdateUser;

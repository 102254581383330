import React from "react";
import Icon from "../../../Constants/icons";

const ActiveExchangeCards = ({ user, bank, time }) => {
  return (
    <div
      className={`flex items-center w-full justify-between border rounded-[3px] p-[12px]`}
    >
      <div className="flex flex-col items-center text-[#666] text-[12px] gap-[9.4px]">
        <span className="w-[35px] h-[35px] rounded-full bg-gray-50">
          <img src={user.image} alt="" />
        </span>
        {user.name}
      </div>
      <div className="flex flex-col items-center text-[#666] text-[12px]">
        <Icon name={"swap"} className={"w-[24px] h-[24px]"} />
        <span>{time}</span>
      </div>
      <div className="flex flex-col items-center text-[#666] text-[12px] gap-[7.4px]">
        {/* <span className="w-[35px] h-[35px] rounded-full bg-gray-50">
          {bank.image}
        </span> */}
        <span className="w-[35px] h-[35px] rounded-full bg-gray-50">
          <img src={bank.image} alt="" />
        </span>
        {bank.name}
      </div>
    </div>
  );
};

export default ActiveExchangeCards;

import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./Views/Pages/Login";
import Signup from "./Views/Pages/Signup";
import ForgotPassword from "./Views/Pages/ForgotPassword";
import SignupOtp from "./Views/Pages/SignupOtp";
import ResetPassword from "./Views/Pages/ResetPassword";
import NewPassword from "./Views/Pages/NewPassword";
import UserSignup from "./Views/Pages/UserSignup";
import NewUser from "./Views/Pages/NewUser";
import Dashboard from "./Views/Pages/Dashboard";
import Overview from "./Views/Pages/Overview";
import Role from "./Views/Pages/Role";
import Report from "./Views/Pages/Report";
import Terminal from "./Views/Pages/Terminal";
import Provider from "./Views/Pages/Provider";
import Merchant from "./Views/Pages/Merchant";
import CreateUser from "./Views/Pages/CreateUser";
import ManageUser from "./Views/Pages/ManageUser";
import Transactions from "./Views/Pages/Transactions";
import useModals from "./Hooks/useModals";

function App() {
  return (
    <div className=" max-w-[1440px] m-auto items-center">
      {useModals()}
      <Routes>
        <Route path="/" element={<Navigate to={"/login"} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-user" element={<SignupOtp />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/new-password" element={<NewPassword />} />
        <Route path="/create-account" element={<UserSignup />} />
        <Route path="/create-new-user" element={<NewUser />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="" element={<Overview />} />
          <Route path="transactions" element={<Transactions />} />
          <Route path="user-management/manage-user" element={<ManageUser />} />
          <Route path="user-management/create-user" element={<CreateUser />} />
          <Route path="merchants" element={<Merchant />} />
          <Route path="service-management" element={<Provider />} />
          <Route path="terminals" element={<Terminal />} />
          <Route path="report&analysis" element={<Report />} />
          <Route path="role-management" element={<Role />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import images from "../../../Constants/images";

const AuthLayout = ({ children }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-full min-h-screen grid grid-cols-12 gap-[88px] py-[65px]">
      <div className="col-span-6 w-full h-full ">
        <div className="flex pt-[60px] flex-col justify-center relative pl-[65px] h-full">
          <span className="absolute top-0 left-[65px]">{images.logo}</span>
          {children}
        </div>
      </div>
      <img
        className="w-full col-span-6 h-full pr-[29px]"
        src={images.authFrame}
        alt=""
      />
    </div>
  );
};

export default AuthLayout;

import React from "react";

const Revenue = ({ label, amount, rate, className, current, hasborder }) => {
  return (
    <div
      className={`col-span-4 px-[10px] pr-[20px] h-[168px] flex items-center justify-between w-full ${className} `}
    >
      <div className={`flex flex-col w-full py-[20px] justify-center gap-1 `}>
        <span className="text-[16px] text-[#333] font-[400] ">{label}</span>
        <span className="text-[24px] text-[#333] font-[500] ">
          ₦{amount}
        </span>
        <span className="text-[12px]">
          {rate}{" "}
          <span className="text-[#565555]">
            {current ? "for this month" : "for last month"}
          </span>
        </span>
      </div>
      {hasborder && (
        <span className="h-[115px] bg-[--shade] w-[1px] mr-[-25px]"></span>
      )}
    </div>
  );
};

export default Revenue;

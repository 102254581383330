import React, { useRef, useState } from "react";
import Icon from "../../../Constants/icons";
import { useFlowContext } from "../../../State/context/FlowContext";
import { AiOutlineEllipsis } from "react-icons/ai";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
// import { string } from "yup";
// import { transactions } from "../../../Constants/table_data";

const Pagination = ({ paginationData }) => {
  const [page, setPage] = useState(paginationData?.page);
  const maxRow = paginationData?.limit;
  const [maxView, setMaxView] = useState(10);
  const [index, setIndex] = useState(0);
  const rows = paginationData?.total;

  const numberOfPages = rows / maxRow;

  const [next, setNext] = useState(paginationData["more-pages"]);
  // const [prev, setPrev] = useState(false);

  const getPages = () => {
    const arr = [];
    for (let i = 0; i < numberOfPages; i++) {
      arr.push(i + 1);
    }
    return arr;
  };

  const pages = getPages();
  // console.log(numberOfPages);
  const changePage = (p) => {
    // console.log("change", p);
    if (p < numberOfPages || p === numberOfPages) {
      if (p - 10 >= 0 && p + 5 < numberOfPages) {
        setMaxView(p + 6);
        setIndex(p - 10 + 5);
        numberOfPages > 10 && p + 5 < numberOfPages && setNext(true);
      } else if (
        (p + 5 > numberOfPages && numberOfPages > 9) ||
        (p + 5 === numberOfPages && numberOfPages > 9) ||
        (p === numberOfPages && numberOfPages > 9)
      ) {
        setMaxView(numberOfPages + 1);
        setIndex(numberOfPages - 10);
        setNext(false);
        numberOfPages > 10 && p + 5 < numberOfPages && setNext(true);
      } else {
        setMaxView(10);
        setIndex(0);
        if (numberOfPages - p !== 0) setNext(true);
        else setNext(false);
      }
      setPage(p);
    }
  };

  const getPageData = async (p) => {
    const res = await paginationData?.navigatePage(p);
    if (res.message && res.message === "success") changePage(p);
  };

  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-1">
        {index !== 0 && (
          <span className="w-[35px] transition-all ease-in-out duration-150 h-[37px] flex items-center justify-center rounded-[5px] border-[#E6E6E6] cursor-pointer border">
            <AiOutlineEllipsis />
          </span>
        )}
        {pages.slice(index, maxView).map((p) => (
          <span
            key={p}
            onClick={() => getPageData(p)}
            className={`w-[35px] transition-all ease-in-out duration-150 h-[37px] flex items-center justify-center rounded-[5px] border-[#E6E6E6] cursor-pointer border ${
              page === p ? "bg-[#4834D4] text-white" : ""
            }`}
          >
            {p}
          </span>
        ))}
        {next && (
          <span className="w-[35px] transition-all ease-in-out duration-150 h-[37px] flex items-center justify-center rounded-[5px] border-[#E6E6E6] cursor-pointer border">
            <AiOutlineEllipsis />
          </span>
        )}
      </div>
      <div className="flex items-center gap-[16px]">
        <span
          onClick={() => (page !== 1 ? getPageData(page - 1) : null)}
          className={`h-[37px] flex gap-[8px] text-[#333] items-center justify-center rounded-[5px] border-[#E6E6E6] cursor-pointer border p-[10px] ${
            page <= 1 ? "opacity-[0.5]" : "opacity-1"
          }`}
        >
          <BsChevronCompactLeft /> Previous
        </span>
        <span
          onClick={() =>
            numberOfPages !== page && paginationData["more-pages"]
              ? getPageData(page + 1)
              : null
          }
          className={`h-[37px] flex gap-[8px] text-[#333] items-center justify-center rounded-[5px] border-[#E6E6E6] cursor-pointer border p-[10px] ${
            numberOfPages === page || !paginationData["more-pages"]
              ? "opacity-[0.5]"
              : "opacity-1"
          }`}
        >
          Next <BsChevronCompactRight />
        </span>
      </div>
    </div>
  );
};

const TableHeader = ({ header, fullwidth }) => {
  const [isChecked, setIsChecked] = useState(false);
  const check = () => {
    setIsChecked(!isChecked);
  };
  return (
    <div
      className={`flex w-full h-[73px] items-center border-b ${
        fullwidth ? "px-5" : "px-0"
      }`}
    >
      {!fullwidth && (
        <span className="w-[7.5%] flex justify-center gap-[8px]">
          <span onClick={check}>
            <Icon
              name={isChecked ? "active_check" : "check"}
              className={"w-[16px] h-[16px] cursor-pointer"}
            />
          </span>
        </span>
      )}

      {header.map((h, n) => (
        <span
          className="whitespace-nowrap truncate text-[14px] text-[#001956]"
          key={n}
          style={{ width: `${h.ratio}%` }}
        >
          {h.name}
        </span>
      ))}

      {!fullwidth && (
        <span className="w-[7.5%] flex justify-center">
          {/* <Icon name={"menu"} className={"w-[20px] h-[20px] cursor-pointer"} /> */}
        </span>
      )}
    </div>
  );
};

const TableBody = ({
  data,
  checkedList,
  append,
  remove,
  header,
  viewRow,
  deleteParam,
  fullwidth,
}) => {
  const page = window.location.href;

  const rowRef = useRef();

  const [isChecked, setIsChecked] = useState(
    checkedList?.includes(data[0]?.id)
  );
  // console.log(deleteParam);
  const {
    rowId,
    setRowId,
    setActiveModal,
    setModalType,
    rowMenu,
    setRowMenu,
    setDeleteParams,
  } = useFlowContext();

  const check = () => {
    if (checkedList?.includes(data[0].id)) {
      remove(data[0].id);
      setIsChecked(false);
    } else {
      append(data[0].id);
      setIsChecked(true);
    }
  };

  const toggler = () => {
    if (!rowMenu && rowId !== data[0].id) {
      setRowMenu(true);
      setRowId(data[0].id);
    } else if (rowMenu && rowId !== data[0].id) {
      setRowId(data[0].id);
      setRowMenu(true);
    } else {
      setRowMenu(false);
      setRowId("");
    }
  };
  // window.addEventListener("click", (e) => {
  //   if (rowMenu && !rowRef.current?.contains(e.target)) {
  //     console.log(e.target);
  //     setRowMenu(false);
  //     setRowId("");
  //   }
  // });
  // console.log(" data[0]", data[0]);
  return (
    <div
      className={`flex w-full min-h-[73px] py-[20px] items-center relative border-b ${
        fullwidth ? "px-5" : "px-0"
      } `}
    >
      {!fullwidth && (
        <span
          style={{
            zIndex: rowMenu && rowId !== data[0].id ? "-1" : "",
          }}
          className="w-[7.5%] flex justify-center gap-[8px]"
        >
          <span onClick={check}>
            <Icon
              name={isChecked ? "active_check" : "check"}
              className={"w-[16px] h-[16px] cursor-pointer"}
            />
          </span>
        </span>
      )}

      {data?.map((d, n) => {
        return (
          <span
            className={`whitespace-nowrap relative capitalize text-[14px] text-[#001956] ${
              typeof d === "string" && " truncate"
            }`}
            onClick={() => {
              setRowMenu(false);
              setRowId("");
            }}
            key={n}
            style={{
              width: `${header[n]?.ratio}%`,
              minWidth: `${header[n]?.ratio}%`,
              //   zIndex: rowMenu && rowId !== data[0].id ? "-1" : "",
            }}
          >
            {d.name}
          </span>
        );
      })}

      {!fullwidth && (
        <div
          ref={rowRef}
          style={{
            zIndex: rowMenu && rowId !== data[0].id ? "-1" : "",
          }}
          className="w-[7.5%] flex justify-center "
        >
          <Icon
            name={"menu"}
            className={"w-[20px] h-[20px] cursor-pointer"}
            onClick={toggler}
          />
        </div>
      )}
      {rowMenu && rowId === data[0].id && (
        <div
          style={{ zIndex: 99999999 }}
          className=" absolute p-[5px] rounded-[5px] gap-[5px] top-[55px] shadow right-0 flex flex-col w-[130px] bg-[#FFF] z-[9999999999999999999999999] "
        >
          <span
            onClick={() => viewRow(data[0].id)}
            className="flex p-[5px] bg-[#FFF] cursor-pointer transition-all all duration-100 items-center gap-[10px] text-[10px] hover:bg-[--shade]"
          >
            <Icon
              name={page.includes("role-management") ? "pen" : "eye"}
              className={"w-[12px] h-[12px] cursor-pointer"}
            />
            {page.includes("role-management") ? "Edit Role" : "View Details"}
          </span>
          <span
            onClick={() => {
              setActiveModal(true);
              setModalType("delete");
              setDeleteParams(deleteParam);
              setRowMenu(false);
            }}
            className="flex p-[5px] cursor-pointer transition-all all duration-100 items-center gap-[10px] text-[10px] hover:bg-[--shade]"
          >
            <Icon
              name={"trash"}
              className={"w-[12px] h-[12px] cursor-pointer"}
            />
            Delete
          </span>
        </div>
      )}
    </div>
  );
};

const Table = ({
  checkedList,
  remove,
  append,
  data,
  header,
  viewRow,
  deleteParam,
  fullwidth,
  hidePagination,
  paginationData,
}) => {
  return (
    <div className="flex flex-col gap-[45px]">
      <div className="border rounded-[8px] w-full flex-col flex">
        <TableHeader header={header} fullwidth={fullwidth} />
        {data.map((d, n) => (
          <TableBody
            key={n}
            data={d}
            checkedList={checkedList}
            append={append}
            remove={remove}
            header={header}
            viewRow={viewRow}
            deleteParam={deleteParam}
            fullwidth={fullwidth}
          />
        ))}
      </div>
      {!hidePagination && <Pagination paginationData={paginationData} />}
    </div>
  );
};

export default Table;

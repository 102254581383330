import React from "react";
import { Input, Select } from "../../../../UI/Utitls/Input";
import { ButtonFill } from "../../../../UI/Utitls/Button";

const BankInfo = ({
  newMerchant,
  newMerChantErrors,
  handleChange_,
  handleSubmit,
  loading,
  selectBank,
  banks
}) => {
  return (
    <div className="flex flex-col gap-[23px]">
      <div className="min-h-[450px]">
        <Select
          label={"Bank Name"}
          value={newMerchant.bankName}
          handleChange={(e) => selectBank(e)}
          name={"bankName"}
          error={newMerChantErrors.bankName}
          options={banks}
        />
        <Input
          label={"Bank Account Number"}
          value={newMerchant.bankAccountNumber}
          handleChange={handleChange_}
          name={"bankAccountNumber"}
          error={newMerChantErrors.bankAccountNumber}
        />
      </div>

      <div className="flex justify-end">
        <ButtonFill
          label={"Create"}
          classes="h-[40px] text-[#fff] max-w-[120px]"
          action={handleSubmit}
          loading={loading}
          active={true}
        />
      </div>
    </div>
  );
};

export default BankInfo;

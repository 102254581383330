import React from "react";

const Analyst = ({ title, children }) => {
  return (
    <div className="px-[21px] py-[24px] w-full h-full flex flex-col gap-[30px]">
      <span className="text-[16px]">{title}</span>
      <div className="flex flex-col gap-[16px]">{children}</div>
    </div>
  );
};

export default Analyst;

import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useClient from "../Helpers/axiosInstance";

export class authService {
  navigate = useNavigate();
  client = useClient();
  login = async (data) => {
    try {
      const res = await this.client.post("/login", {
        username: data.email,
        password: data.password,
      });
      localStorage.setItem("token", res.data.authorisation.token);
      localStorage.setItem(
        "permissions",
        window.btoa(JSON.stringify(res.data.permissions))
      );
      localStorage.setItem(
        "middleware",
        window.btoa(JSON.stringify(res.data.user))
      );
      return { message: "success" };
    } catch (err) {
      // console.log(err);
      if (err.response.status === 401) {
        toast.error("Invalid email or password");
      } else toast.error("Something went wrong! Please try again.");
      return { message: "failed" };
    }
  };

  forgotPassword = async (email) => {
    try {
      const res = await this.client.post("/auth/password/send-reset-token", {
        email: email,
      });
      console.log(res);
      return { message: "success" };
    } catch (err) {
      // console.log(err);
      if (err.response.status === 401) {
        toast.error("Invalid email or password");
      } else toast.error("Something went wrong! Please try again.");
      return { message: "failed" };
    }
  };
  logout = () => {
    localStorage.clear();
    this.navigate("/login");
  };
}

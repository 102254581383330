import { useEffect, useState } from "react";
import { merchantService } from "../Services/merchants.service";
import { useFlowContext } from "../State/context/FlowContext";
import { useDataContext } from "../State/context/DataContext";
import { banks } from "../Assets/json/banks";
import { toast } from "react-hot-toast";

const useMerchant = () => {
  const {
    filterMerchantById,
    createMerchant,
    // updateMerchant
  } = new merchantService();
  const { users } = useDataContext();
  const { rowId, setCreateScreen } = useFlowContext();

  const [active, setActive] = useState("Merchant Info");
  const [loading, setLoading] = useState(false);

  const merchantUsers = users.data.map((d) => {
    return { name: d.username, id: d.id };
  });

  const [merchantInfo, setMerchantInfo] = useState({
    name: "",
    mid: "",
    description: "",
    existingUsername: "",
  });
  const [bankInfo, setBankInfo] = useState({
    bankName: "",
    bankAccountNumber: "",
  });
  const [contactInfo, setContactInfo] = useState({
    email: "",
    phone: "",
    address: "",
    city: "",
    country: "Nigeria",
  });

  const [newMerChantErrors, setNewMerchantErrors] = useState({
    name: "",
    mid: "",
    description: "",
    email: "",
    phone: "",
    bankName: "",
    bankAccountNumber: "",
    address: "",
    city: "",
    country: "",
    existingUsername: "",
  });

  const [form, setForm] = useState({
    address: "NO.17 MUSA YARADUA STREET VICTORIA ISLAND",
    bank_account_number: "0011223344",
    bank_name: "GUARANTY TRUST BANK",
    city: "Lagos",
    contact_email: "merchant@test.com",
    contact_phone: "08011001100",
    country: "Nigeria",
    description: "Merchant data for test",
    mid: "AKJ690931150858",
    name: "Merchant Test",
  });

  const [errors, setErrors] = useState({
    name: "",
    description: "",
    contact_email: "",
    contact_phone: "",
    bank_name: "",
    bank_account_number: "",
    address: "",
    city: "",
    country: "",
  });

  const getMerchant = async () => {
    const data = await filterMerchantById(rowId);
    // console.log(data);
    data &&
      setMerchantInfo({
        name: data.name,
        mid: data.mid,
        description: data.description,
        existingUsername: data.existingUsername,
      });
    data &&
      setContactInfo({
        email: data.contact_email,
        phone: data.contact_phone,
        address: data.address,
        city: data.city,
        country: "Nigeria",
      });
    data &&
      setBankInfo({
        bankName: data.bank_name,
        bankAccountNumber: data.bank_account_number,
      });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const updateMerchantInfo = (e) => {
    const { name, value } = e.target;
    setMerchantInfo({ ...merchantInfo, [name]: value });
    if (value === "")
      setNewMerchantErrors({ ...newMerChantErrors, [name]: "Required*" });
    else setNewMerchantErrors({ ...newMerChantErrors, [name]: "" });
  };
  const updateBankInfo = (e) => {
    const { name, value } = e.target;
    setBankInfo({ ...bankInfo, [name]: value });
    if (value === "")
      setNewMerchantErrors({ ...newMerChantErrors, [name]: "Required*" });
    else setNewMerchantErrors({ ...newMerChantErrors, [name]: "" });
  };
  const updateContactInfo = (e) => {
    const { name, value } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
    if (value === "")
      setNewMerchantErrors({ ...newMerChantErrors, [name]: "Required*" });
    else if (name === "email" && !validateEmail(value))
      setNewMerchantErrors({
        ...newMerChantErrors,
        [name]: "Invalid email address*",
      });
    else setNewMerchantErrors({ ...newMerChantErrors, [name]: "" });
  };

  const handleExistingUser = (e) => {
    setMerchantInfo({ ...merchantInfo, existingUsername: e });
    setNewMerchantErrors({ ...newMerChantErrors, existingUsername: "" });
  };

  const selectBank = (e) => {
    setBankInfo({ ...bankInfo, bankName: e.name });

    // setNewMerchantErrors({ ...errors, bankName: "" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (name === "contact_email" && !validateEmail(value))
      setErrors({ ...errors, [name]: "Invalid email address*" });
    else setErrors({ ...errors, [name]: "" });
  };
  const validateMerchantInfo = () => {
    const {
      name,
      mid,
      description,
      // existingUsername
    } = merchantInfo;
    const newErrors = {};
    if (name === "") newErrors.name = "Required*";
    if (mid === "") newErrors.mid = "Required*";
    if (description === "") newErrors.description = "Required*";
    // if (existingUsername === "") newErrors.existingUsername = "Required*";
    return newErrors;
  };

  const validateBankInfo = () => {
    const { bankName, bankAccountNumber } = bankInfo;
    const newErrors = {};
    if (bankName === "") newErrors.bankName = "Required*";
    if (bankAccountNumber === "") newErrors.bankAccountNumber = "Required*";
    return newErrors;
  };
  const validateContactInfo = () => {
    const { email, phone, address, city, country } = contactInfo;
    const newErrors = {};
    if (email === "") newErrors.email = "Required*";
    else if (!validateEmail(email)) newErrors.email = "Email is invalid*";
    if (phone === "") newErrors.phone = "Required*";
    if (address === "") newErrors.address = "Required*";
    if (city === "") newErrors.city = "Required*";
    if (country === "") newErrors.country = "Required*";
    return newErrors;
  };

  const submitNewMerchant = async (label, next) => {
    if (label === "Merchant Info") {
      if (Object.keys(validateMerchantInfo()).length > 0) {
        setNewMerchantErrors({
          ...newMerChantErrors,
          ...validateMerchantInfo(),
        });
      } else {
        // console.log(label, next);
        next ? setActive("Contact Info") : setActive(label);
      }
    } else if (label === "Contact Info") {
      // console.log(contactInfo);
      if (Object.keys(validateContactInfo()).length > 0) {
        setNewMerchantErrors({
          ...newMerChantErrors,
          ...validateContactInfo(),
        });
      } else {
        next ? setActive("Bank Info") : setActive(label);
      }
    } else {
      if (Object.keys(validateBankInfo()).length > 0) {
        setNewMerchantErrors({
          ...newMerChantErrors,
          ...validateBankInfo(),
        });
      } else {
        setLoading(true);
        const res = await createMerchant({
          ...merchantInfo,
          ...contactInfo,
          ...bankInfo,
        });
        setLoading(false);
        if (res.message === "success") setCreateScreen(false);
      }
    }
  };

  const updateMerchant_ = async () => {
    const errs = {
      ...validateBankInfo(),
      ...validateContactInfo(),
      ...validateMerchantInfo(),
      //
    };
    if (Object.keys(errs).length > 0) {
      setNewMerchantErrors({ ...newMerChantErrors, ...errs });
      // setN
    } else {
      setLoading(true);
      const res = await createMerchant({
        ...merchantInfo,
        ...contactInfo,
        ...bankInfo,
      });
      if (res.message === "success") {
        getMerchant();
        toast.success("Updated successfully");
      } else {
        toast.error("Something went wrong, Please try again");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getMerchant();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    form,
    handleChange,
    errors,
    newMerChantErrors,
    active,
    bankInfo,
    contactInfo,
    merchantInfo,
    loading,
    merchantUsers,
    updateBankInfo,
    updateMerchantInfo,
    updateContactInfo,
    submitNewMerchant,
    handleExistingUser,
    banks,
    selectBank,
    updateMerchant_,
  };
};

export default useMerchant;

import React, { useState } from "react";
// import Icon from "../../../Constants/icons";
import { RiUploadCloud2Line } from "react-icons/ri";
import { ButtonFill } from "../Utitls/Button";

const Upload = ({ upload, loading }) => {
  const [file, setFile] = useState(null);
  const formData = new FormData();

  const handlupload = () => {
    formData.append("file", file);
    // console.log(file)
    upload(file)
  };

  return (
    <div className="w-[500px] gap-4 bg-[#fff] flex flex-col px-10 py-5 justify-center items-center">
      <div className="flex relative flex-col min-h-[250px] items-center justify-center gap-4 border border-dashed w-full h-full bg-[rgba(72,52,212,0.03)]">
        <RiUploadCloud2Line className="text-[80px] text-[rgb(72,52,212)] " />
        <span className="text-[14px] font-[500] text-gray-300">
          Upload Document (cvs/excel)
        </span>
        <span className="text-[12px] text-gray-400">{file?.name}</span>
        <input
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          className=" absolute left-0 right-0 top-0 bottom-0 w-full h-full opacity-0"
        />
      </div>
      <ButtonFill
        label={"upload"}
        classes="h-[40px] text-[#fff]"
        action={handlupload}
        loading={loading}
        active={true}
      />
    </div>
  );
};

export default Upload;

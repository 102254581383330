import React, { useState } from "react";
import Icon from "../../../Constants/icons";
import Loader from "./Loader";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-hot-toast";

const Search = ({ handleSearch }) => {
  const [search, setSearch] = useState("");
  const [activeSearch, setActiveSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const runSearch = async () => {
    if (search !== "") {
      setLoading(true);
      console.log("test");
      const res = await handleSearch(search);
      if (res?.message !== "success") {
        toast.error("Item item found");
      }

      setTimeout(() => {
        setLoading(false);
        setActiveSearch(true);
      }, 50);
    }
  };
  const cancelSearch = async () => {
    await handleSearch("");
    setActiveSearch(false);
    setSearch("");
  };
  return (
    <div className="flex gap-[8px] h-[44px] w-[438px] rounded-[8px] border items-center p-[10px] border-[#E6E6E6]">
      <span onClick={runSearch} className=" cursor-pointer w-[32px]">
        <Icon className={"w-[24px] h-[24px]"} name={"search"} />
      </span>
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        type="text"
        placeholder="Search"
        className="w-full border-none outline-none"
      />
      {loading && <Loader size={[20, 20]} color={"gray"} />}
      {!loading && activeSearch && (
        <span
          onClick={cancelSearch}
          className="flex items-center justify-center min-w-[27px] h-[27px] rounded-full cursor-pointer bg-gray-100"
        >
          <IoMdClose className="cursor-pointer" />
        </span>
      )}
    </div>
  );
};

export default Search;

import React from "react";
import { vectors } from "../../../Constants/vectors";

const ImageLayout = ({ bg, children, className }) => {
  return (
    <div className={`relative ${className}`}>
      <img
        className="absolute z-[-1] top-0 left-0 w-full h-full"
        src={vectors[bg]}
        alt=""
      />
      {children}
    </div>
  );
};

export default ImageLayout;

// import React from "react";
// import { toast } from "react-hot-toast";
import { useDataContext } from "../State/context/DataContext";
import useClient from "../Helpers/axiosInstance";

export class providerService {
  context = useDataContext();
  client = useClient();
  fetchProviders = async () => {
    try {
      const res = await this.client.get("/providers");
      this.context.setProviders(res.data);
      return { message: "success" };
    } catch (err) {
      console.log(err);
    }
  };
  switchProvider = async (id) => {
    try {
       await this.client.put(`/providers/${id}/switch-status`);
      this.fetchProviders();
      return { message: "success" };
    } catch (err) {
      console.log(err);
    }
  };
}

import  { useState } from "react";
import { useDataContext } from "../State/context/DataContext";
import { userService } from "../Services/user.service";
import { banks } from "../Assets/json/banks";

const useCreateUser = () => {
  const service = new userService();
  const [form, setForm] = useState({
    role: { name: "", id: "" },
    permission: { name: "", id: "" },
    full_name: "",
    email: "",
    bank: { name: "", id: "" },
    account_number: "",
  });
  const [errors, setErrors] = useState({
    role: "",
    permission: "",
    full_name: "",
    email: "",
    password: "",
    bank: "",
    account_number: "",
  });
  const [loading, setLoading] = useState(false);
  const { roles, permissions } = useDataContext();
  const userRoles = roles.data;

  const [userPermissions, setUserpermissions] = useState([]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  // const strongPassword = (password) => {
  //   const strength = new RegExp(
  //     "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  //   );
  //   return strength.test(password);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    if (value === "") setErrors({ ...errors, [name]: "Required*" });
    else if (
      name === "full_name" &&
      (!value.includes(" ") || value.split(" ").length < 2)
    ) {
      setErrors({ ...errors, [name]: "Enter your full name*" });
    } else if (name === "full_name" && value.includes(" ")) {
      const arr = value.split(" ");
      // console.log(arr);
      if (!arr[0] || arr[0] === "" || !arr[1] || arr[1] === "") {
        // console.log("test");
        setErrors({ ...errors, [name]: "Enter your full name*" });
      } else setErrors({ ...errors, [name]: "" });
    } else if (name === "email" && !validateEmail(value))
      setErrors({ ...errors, [name]: "Invalid email address*" });
    // else if (name === "password" && !strongPassword(value))
    //   setErrors({ ...errors, [name]: "Password is weak*" });
    else setErrors({ ...errors, [name]: "" });
  };

  const handleSelect = (e) => {
    setForm({ ...form, role: e });
    setErrors({ ...errors, role: "" });
  };
  const handleSelectBank = (e) => {
    console.log(e);
    setForm({ ...form, bank: e });
    setErrors({ ...errors, bank: "" });
  };

  const handlePermission = (e) => {
    const selected = userPermissions.find((p) => p.id === e.id);
    if (!selected) {
      setUserpermissions([...userPermissions, e]);
      setForm({ ...form, permission: e });
      setErrors({ ...errors, permission: "" });
    }
  };
  const removePermission = (e) => {
    const newPermissions = userPermissions.filter((p) => p.id !== e.id);
    setUserpermissions(newPermissions);
  };

  const validateForm = () => {
    const { role, full_name, email, bank } = form;
    const newErrors = {};
    if (role.id === "") newErrors.role = "Required*";
    if (full_name === "") newErrors.full_name = "Required*";
    else if (!full_name.includes(" "))
      newErrors.full_name = "Enter your full name*";
    else if (
      full_name.split(" ").length < 2 ||
      !full_name.split(" ")[0] ||
      !full_name.split(" ")[1] ||
      !full_name.split(" ")[0] === "" ||
      !full_name.split(" ")[1] === ""
    )
      newErrors.full_name = "Enter your full name*";
    if (email === "") newErrors.email = "Required*";
    else if (!validateEmail(email)) newErrors.email = "Invalid email address*";
    if (userPermissions.length < 3)
      newErrors.permission = "User must have at least 3 permissions";
    if (role.name.toLowerCase() === "bank" && bank.name === "")
      newErrors.bank = "";
    return newErrors;
  };

  const handleSubmit = async () => {
    if (Object.keys(validateForm()).length > 0) {
      setErrors({ ...errors, ...validateForm() });
    } else {
      setLoading(true);
      const res = await service.createUser({
        username: form.email,
        password: form.email.toUpperCase(),
        lastName: form.full_name.split(" ")[1],
        firstName: form.full_name.split(" ")[0],
        middleName: "",
        phone: "",
        role: form.role.name,
        bank: form.bank.name,
        account_number: form.account_number,
        permissions: userPermissions.map((p) => p.name),
      });
      if (res.message === "success") {
        setForm({
          username: "",
          password: "",
          lastName: "",
          firstName: "",
          middleName: "",
          phone: "",
          role: "",
          bank: "",
          account_number: "",
          permissions: [],
        });
        setUserpermissions([]);
      }
      setLoading(false);
    }
  };

  return {
    form,
    errors,
    loading,
    userRoles,
    userPermissions,
    permissions,
    banks,
    handleChange,
    handleSubmit,
    handleSelect,
    handlePermission,
    removePermission,
    handleSelectBank,
  };
};

export default useCreateUser;

import React from "react";
import {
  ResponsiveContainer,
  // ComposedChart,
  // Line,
  // Area,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  // Legend,
} from "recharts";
import {
  // area_data,
  barchart_data,
} from "../../../Constants/dummy_data";
// import { Select } from "../../UI/Utitls/Input";
// import ImageLayout from "../../UI/Layouts/ImageLayout";

const BarChart_ = () => {
  return (
    <div className="w-full p-[22px] h-[283.164px]">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <span className="text-[#333] text-[16px] ">Transaction Metrics</span>
        </div>
        <div className="flex gap-[29px] items-center ">
          <div className="flex items-center gap-[16px]">
            <span className="flex items-center gap-[8px] text-[#333] text-[14px]">
              <span className="w-[12px] h-[12px] rounded-full bg-[#5DB86C]"></span>
              Success
            </span>
            <span className="flex items-center gap-[8px] text-[#333] text-[14px]">
              <span className="w-[12px] h-[12px] rounded-full bg-[#ED1313]"></span>
              Error
            </span>
          </div>
          <div className="flex items-center h-full">
            {/* <Select
              value={"Week"}
              handleChange={() => {}}
              name={"week"}
              error={""}
              options={["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"]}
            /> */}
          </div>
        </div>
      </div>
      <div className="w-full h-[200px] text-[#563bb881]">
        <ResponsiveContainer
          className={"ml-[-50px]"}
          width={"100%"}
          height={263}
        >
          <BarChart
            width={600}
            height={313}
            data={barchart_data}
            margin={{
              top: 0,
              right: 20,
              bottom: 20,
              left: 0,
            }}
            barGap={0}
          >
            <XAxis
              dataKey="date"
              tickLine={false}
              padding={{ top: 30, bottom: 30 }}
              dy={20}
            />
            <YAxis tick={false} tickLine={false} />
            <Tooltip cursor={{ fill: "transparent" }} content={<></>} />
            <Bar
              dataKey="amClass.success"
              stackId="amClass" // All the Bars with this stackId will be in one stack
              fill={"#5DB86C"}
            />
            <Bar
              dataKey="pmClass.error"
              stackId="pmClass" // Then three more bars to make the PM stack
              fill={"#ED1313"}
            />

            {/* <Legend /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default BarChart_;

import React from "react";
import AreaChart from "../Components/Charts/AreaChart";
import ActiveMerchants from "../Components/Sections/Overview/ActiveMerchants";
import ActiveEntities from "../Components/Sections/Overview/ActiveEntities";
import BarChart from "../Components/Charts/BarChart";

const Overview = () => {
  return (
    <div className="w-full flex flex-col gap-[25px]">
      <div className="w-full grid grid-cols-12 gap-[21px] h-[377px]">
        <div className="col-span-8 border h-[388px] rounded-[8px]">
          <AreaChart />
        </div>
        <div className="col-span-4 border rounded-[8px] h-[388px] overflow-y-auto">
          <ActiveMerchants />
        </div>
      </div>
      <div className="w-full grid grid-cols-12 gap-[21px] h-[377px]">
        <div className="col-span-8 border h-[388px] rounded-[8px]">
          <BarChart />
        </div>
        <div className="col-span-4 border rounded-[8px] h-[388px] overflow-y-auto">
          <ActiveEntities />
        </div>
      </div>
    </div>
  );
};

export default Overview;

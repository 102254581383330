import React, { useEffect, useState } from "react";
import { useFlowContext } from "../../../../State/context/FlowContext";
import { useDataContext } from "../../../../State/context/DataContext";

const Transaction = () => {
  const { rowId } = useFlowContext();
  const { transactions } = useDataContext();

  const [transaction, setTransaction] = useState({});

  const getTransaction = async () => {
    const item = transactions?.data?.find((item) => item.id === rowId);
    // console.log("transaction", item);
    setTransaction(item);
  };
  useEffect(() => {
    getTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col gap-10">
      <div className="grid grid-cols-12  bg-[rgba(72,52,212,0.03)] rounded-[20px]">
        <div className="col-span-4 p-5">
          <span className="text-[18px]">Transaction Info</span>
        </div>
        <div className="col-span-12 grid grid-cols-6 gap-y-3 p-5  text-[gray]">
          <span className="col-span-3">Transaction Type :</span>
          <span className="col-span-3">{transaction?.type}</span>
          <span className="col-span-3">Transaction Refrence :</span>
          <span className="col-span-3">
            {transaction?.transaction_reference}
          </span>
          <span className="col-span-3">Transaction Status :</span>
          <span className="col-span-3">{transaction?.status}</span>
          <span className="col-span-3">Transaction Amount :</span>
          <span className="col-span-3">
            {transaction?.currency} {transaction?.amount}
          </span>
          <span className="col-span-3">Transaction Date :</span>
          <span className="col-span-3">
            {transaction?.transaction_date?.slice(0, 11)}{" "}
            {transaction?.transaction_date?.slice(24)}
          </span>
          <span className="col-span-3">PAN :</span>
          <span className="col-span-3">{transaction?.pan}</span>
          <span className="col-span-3">RRN :</span>
          <span className="col-span-3">{transaction?.rrn}</span>
          <span className="col-span-3">Card Type :</span>
          <span className="col-span-3">{transaction?.card_type}</span>
          <span className="col-span-3">Card Bank :</span>
          <span className="col-span-3">{transaction?.card_bank}</span>
        </div>
      </div>
      <div className="grid grid-cols-12  bg-[rgba(72,52,212,0.03)] rounded-[20px]">
        <div className="col-span-4 p-5">
          <span className="text-[18px]">Terminal Info</span>
        </div>
        <div className="col-span-12 grid grid-cols-6 gap-y-3 p-5  text-[gray]">
          <span className="col-span-3">Terminal ID :</span>
          <span className="col-span-3">{transaction?.terminal?.tid}</span>
          <span className="col-span-3">Terminal Address :</span>
          <span className="col-span-3">{transaction?.terminal?.address}</span>
          <span className="col-span-3">Is Instant Settlement :</span>
          <span className="col-span-3">
            {transaction?.terminal?.is_instant_settlement === 0
              ? "FALSE"
              : "TRUE"}
          </span>
          <span className="col-span-3">Terminal Operator :</span>
          <span className="col-span-3">{transaction?.terminal?.operator}</span>
          <span className="col-span-3">Terminal Type :</span>
          <span className="col-span-3">{transaction?.terminal?.type}</span>
          <span className="col-span-3">Terminal Serial Number :</span>
          <span className="col-span-3">{transaction?.terminal?.serial_number}</span>
          <span className="col-span-3">Terminal Slip Header :</span>
          <span className="col-span-3">{transaction?.terminal?.slip_header}</span>
          <span className="col-span-3">Terminal Slip Footer :</span>
          <span className="col-span-3">{transaction?.terminal?.slip_footer}</span>
        </div>
      </div>
      <div className="grid grid-cols-12  bg-[rgba(72,52,212,0.03)] rounded-[20px]">
        <div className="col-span-4 p-5">
          <span className="text-[18px]">Provider Info</span>
        </div>
        <div className="col-span-12 grid grid-cols-6 gap-y-3 p-5  text-[gray]">
          <span className="col-span-3">Provider :</span>
          <span className="col-span-3">{transaction?.provider?.name}</span>
          <span className="col-span-3">Provider Status :</span>
          <span className="col-span-3">
            {transaction?.provider?.active === 0 ? "Inactive" : "Active"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Transaction;

import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import { authService } from "../Services/authService.service";

const useClient = () => {
  const navigate = useNavigate();
  const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  if (localStorage.token) {
    client.interceptors.request.use(function (config) {
      config.headers["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      return config;
    });
    client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error?.response?.status === 401) {
          localStorage.clear();
          toast.error("Sesseion Expired");
          // service.logout();
          navigate("/login");
        }
        return Promise.reject(error);
      }
    );
  }
  return client;
};
export default useClient;

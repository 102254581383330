import React, { useRef, useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import OTPInput from "react-otp-input";
// import icons from "../../../Constants/icons";
import Icon from "../../../Constants/icons";

export const Input = ({
  label,
  value,
  handleChange,
  name,
  error,
  disabled,
  max,
}) => {
  const border_class =
    error === "" ? " border-[gray_text] " : " border-[#FF0018] ";
  return (
    <div className="flex flex-col gap-[8px]">
      <span className="text-[16px] capitalize text-[#666666] flex justify-between items-center">
        {label}
      </span>
      {!disabled ? (
        <input
          type="text"
          value={value}
          name={name}
          placeholder={label}
          onChange={handleChange}
          maxLength={max ? max : 600}
          className={`border text-[#333333] w-full h-[47px] rounded-[8px] outline-none px-[16px] ${border_class}`}
        />
      ) : (
        <input
          type="text"
          defaultValue={value}
          name={name}
          placeholder={label}
          disabled
          className={`border text-[#333333] cursor-not-allowed w-full h-[47px] rounded-[8px] outline-none px-[16px] ${border_class}`}
        />
      )}
      {!disabled && (
        <span className="h-[10px] text-[#FF0018] text-[10px]">{error}</span>
      )}
    </div>
  );
};
export const PasswordInput = ({
  label,
  value,
  handleChange,
  name,
  error,
  auto,
}) => {
  const [type, setType] = useState("password");
  const border_class =
    error === "" ? " border-[gray_text] " : " border-[#FF0018] ";
  return (
    <div className="flex flex-col gap-[8px]">
      <span className="text-[16px] capitalize text-[#666666] flex justify-between items-center">
        {label}
      </span>
      <div className="relative w-full">
        <input
          type={type}
          value={value}
          name={name}
          onChange={handleChange}
          placeholder={label}
          autoComplete={auto ? "on" : "off"}
          className={`border text-[#333333]  w-full h-[47px] rounded-[8px] outline-none px-[16px] ${border_class}`}
        />
        <span className="absolute h-full flex items-center justify-center right-0 bottom-0 top-0 w-[36px]">
          {type === "password" ? (
            <AiOutlineEye
              onClick={() => setType("text")}
              className="cursor-pointer text-[gray_text] text-[16px]"
            />
          ) : (
            <AiOutlineEyeInvisible
              onClick={() => setType("password")}
              className="cursor-pointer text-[gray_text] text-[16px]"
            />
          )}
        </span>
      </div>
      <span className="h-[10px] text-[#FF0018] text-[10px]">{error}</span>
    </div>
  );
};

export const Select = ({
  label,
  value,
  handleChange,
  name,
  error,
  options,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const optionRef = useRef();
  window.addEventListener("click", (e) => {
    if (open && !optionRef.current?.contains(e.target)) toggle();
  });
  console.log(typeof options[0]);
  const toggle = () => {
    if (!open) {
      setOpen(true);
    } else {
      setTimeout(() => {
        setOpen(false);
      }, 50);
    }
  };

  const border_class =
    error === "" ? " border-[gray_text] " : " border-[#FF0018] ";

  return (
    <div className="flex flex-col gap-[8px]">
      {label && (
        <span className="text-[16px] capitalize text-[#666666] flex justify-between items-center">
          {label}
        </span>
      )}
      <div
        ref={optionRef}
        className={`relative w-full flex items-center border text-[gray_text] h-[47px] rounded-[8px] outline-none px-[16px] ${border_class}`}
      >
        <div
          onClick={toggle}
          className={`flex justify-between ${
            disabled ? "cursor-not-allowed" : "cursor-pointer"
          } items-center w-full`}
        >
          <span
            className={`${
              value !== "" ? "text-[#333333]" : "text-[#333] opacity-[0.5]"
            } capitalize`}
          >
            {value === "" ? label : value}
          </span>
          <Icon
            name={"chevron"}
            className={`cursor-pointer transition-all duration-100 ${
              open ? " rotate-180" : " rotate-0"
            }`}
          />
        </div>
        {open && !disabled && (
          <div className="absolute px-[16px] py-[10px] rounded-[8px] z-[999] bg-[#fcfcfc] max-h-[200px] overflow-y-auto flex flex-col justify-between shadow-sm w-full top-[47px] left-0">
            {options?.map((opt, n) => {
              return (
                <span
                  onClick={() => {
                    handleChange(opt);
                    toggle();
                  }}
                  className="h-[30px] capitalize flex items-center w-full cursor-pointer text-[14px] "
                  key={n}
                >
                  {opt?.name?.replaceAll("-", " ")}
                </span>
              );
            })}
          </div>
        )}
      </div>
      <span className="h-[10px] text-[#FF0018] text-[10px]">{error}</span>
    </div>
  );
};

export const OtpInput = ({ otp, setOtp, countdown }) => {
  return (
    <div className="flex w-full flex-col items-center gap-[40px]">
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        renderSeparator={<span> </span>}
        inputType="password"
        renderInput={(props) => <input {...props} />}
        inputStyle={
          "text-[18px] text-[#999] border border-[#E6E6E6] outline-[#4834D4] rounded-[8px] flex min-w-[44px] h-[44px]"
        }
        containerStyle={"w-full flex justify-between "}
      />
      <span
        className={`text-[#333] text-[16px] ${
          countdown === "00:00" ? "cursor-pointer" : "cursor-not-allowed"
        }`}
      >
        Resend in <span className="text-[--dark]">{countdown} minutes</span>
      </span>
    </div>
  );
};

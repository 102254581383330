import React from "react";
import CreateEntityLayout from "../../../UI/Layouts/CreateEntityLayout";
import vector from "../../../../Assets/vectors/user_vector.png";
import { Input, Select } from "../../../UI/Utitls/Input";
import useTerminal from "../../../../Hooks/useTerminal";
import { ButtonFill } from "../../../UI/Utitls/Button";

const CreateTerminal = () => {
  const {
    form,
    errors,
    loading,
    // merchantNames,
    handleSelect,
    handleChange,
    handleSubmit,
    merchants_,
    banks,
  } = useTerminal();
  // console.log(merchantNames);
  return (
    <CreateEntityLayout title={"Create New Terminal"} vector={vector}>
      <div className="flex flex-col gap-">
        <Select
          label={"Merchant Name"}
          value={form.merchantName}
          handleChange={(e) => handleSelect(e, "merchantName")}
          name={"merchantName"}
          error={errors.merchantName}
          options={merchants_}
        />
        <Input
          label={"Terminal ID"}
          value={form.tid}
          handleChange={handleChange}
          name={"tid"}
          error={errors.tid}
          max={8}
        />
        <Input
          label={"Terminal Type"}
          value={form.type}
          handleChange={handleChange}
          name={"type"}
          error={errors.type}
        />
        <Input
          label={"Operator"}
          value={form.operator}
          handleChange={handleChange}
          name={"operator"}
          error={errors.operator}
        />
        <Input
          label={"Serial Number"}
          value={form.serialNumber}
          handleChange={handleChange}
          name={"serialNumber"}
          error={errors.serialNumber}
        />
        <Select
          label={"Bank Code"}
          value={form.bankCode.name + " - " + form.bankCode.code}
          handleChange={(e) => handleSelect(e, "bankCode")}
          name={"bankCode"}
          error={errors.bankCode}
          options={banks}
        />
        <Input
          label={"Slip Header"}
          value={form.slipHeader}
          handleChange={handleChange}
          name={"slipHeader"}
          error={errors.slipHeader}
        />
        <Input
          label={"Slip Footer"}
          value={form.slipFooter}
          handleChange={handleChange}
          name={"slipFooter"}
          error={errors.slipFooter}
        />
        <ButtonFill
          label={"Create"}
          classes="h-[45px] text-[#fff] "
          action={handleSubmit}
          loading={loading}
          active={true}
        />
      </div>
    </CreateEntityLayout>
  );
};

export default CreateTerminal;

import React from "react";
import Analyst from "../../../UI/Layouts/Analyst";
import ActiveExchangeCards from "../../../UI/Cards/ActiveExchangeCard";
import images from "../../../../Constants/images";

const ActiveExchange = () => {
  return (
    <Analyst title={"Data Exchange Between Entities"}>
      {[0, 1, 2, 3, 4].map((_a, n) => (
        <ActiveExchangeCards
          key={n}
          user={{ name: "Topsy Foods", image: images.avatar }}
          bank={{ name: "Access Bank", image: images.bank }}
          time={"7sec"}
        />
      ))}
    </Analyst>
  );
};

export default ActiveExchange;

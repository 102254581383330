import React from "react";
import useMerchant from "../../../../Hooks/useMerchant";
import { Input, Select } from "../../../UI/Utitls/Input";
import { ButtonFill } from "../../../UI/Utitls/Button";

const Merchant = () => {
  const {
    contactInfo,
    merchantInfo,
    bankInfo,
    newMerChantErrors,
    // active,
    loading,
    // merchantUsers,
    updateBankInfo,
    updateContactInfo,
    updateMerchantInfo,
    // handleExistingUser,
    // handleUpdateMerchant,
    banks,
    selectBank,
    updateMerchant_,
  } = useMerchant();
  return (
    <div className="flex flex-col mb-[43px]">
      <div className="grid grid-cols-12 py-[44px] border-y">
        <span className="col-span-4">Merchant Details</span>
        <div className="col-span-8 ">
          <div className="max-w-[534px] flex flex-col gap-[29px]">
            <Input
              label={"Merchant Name"}
              value={merchantInfo.name}
              handleChange={updateMerchantInfo}
              name={"name"}
              error={newMerChantErrors.name}
            />

            <Input
              label={"Description"}
              value={merchantInfo.description}
              handleChange={updateMerchantInfo}
              name={"description"}
              error={newMerChantErrors.description}
            />

            {/* <Select
              label={"Assign to user"}
              value={merchantInfo.existingUsername}
              handleChange={(e) => handleExistingUser(e)}
              name={"existingUsername"}
              error={newMerChantErrors.existingUsername}
              options={merchantUsers}
            /> */}

            <Input
              label={"Merchant ID"}
              value={merchantInfo.mid}
              handleChange={updateMerchantInfo}
              name={"mid"}
              disabled={true}
              error={newMerChantErrors.mid}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 py-[44px] border-y">
        <span className="col-span-4">Bank Details</span>
        <div className="col-span-8 ">
          <div className="max-w-[534px] flex flex-col gap-[29px]">
            {/* <Input
              label={"Bank Name"}
              value={bankInfo.bankName}
              handleChange={updateBankInfo}
              name={"bankName"}
              error={newMerChantErrors.bankName}
            /> */}
            <Select
              label={"Bank Name"}
              value={bankInfo.bankName}
              handleChange={(e) => selectBank(e)}
              name={"bankName"}
              error={newMerChantErrors.bankName}
              options={banks}
            />
            <Input
              label={"Bank Account Number"}
              value={bankInfo.bankAccountNumber}
              handleChange={updateBankInfo}
              name={"bankAccountNumber"}
              error={newMerChantErrors.bankAccountNumber}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 py-[44px] border-y">
        <span className="col-span-4">Contact Details</span>
        <div className="col-span-8 ">
          <div className="max-w-[534px] flex flex-col gap-[29px]">
            <Input
              label={"Email address"}
              value={contactInfo.email}
              handleChange={updateContactInfo}
              name={"email"}
              error={newMerChantErrors.email}
            />
            <Input
              label={"Phone Number"}
              value={contactInfo.phone}
              handleChange={updateContactInfo}
              name={"phone"}
              error={newMerChantErrors.phone}
            />
            <Input
              label={"Address"}
              value={contactInfo.address}
              handleChange={updateContactInfo}
              name={"address"}
              error={newMerChantErrors.address}
            />
            <Input
              label={"City"}
              value={contactInfo.city}
              handleChange={updateContactInfo}
              name={"city"}
              error={newMerChantErrors.city}
            />{" "}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 items-end ">
        <span className="col-span-4"></span>
        <div className="col-span-8 pt-4 ">
          <ButtonFill
            label={"Save"}
            classes="h-[55px] max-w-[543px]  text-[#fff] "
            action={updateMerchant_}
            loading={loading}
            active={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 py-[44px]">
        <span className="col-span-4">Activity</span>
        <div className="col-span-8 flex flex-col gap-[28px]">
          {/* <div className="flex items-center gap-[11px]">
            <span className="bg-[#5db86c1a] text-[#5DB86C] py-[8px] px-[10px] rounded-[36px]">
              Active
            </span>
            <span className="text-[14px] text-[gray_text]">
              Last Seen: 3 Minutes ago
            </span>
          </div> */}
          <div className="flex items-center gap-[11px]">
            <span className="flex-[0.4] rounded-[36px]">Created At: </span>
            <span className="flex-[0.6] text-[14px] text-[gray_text]"></span>
          </div>
          <div className="flex items-center gap-[11px]">
            <span className="flex-[0.4] rounded-[36px]">Updated At:</span>
            <span className="flex-[0.6] text-[14px] text-[gray_text]"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Merchant;

// import React from "react";
// import { toast } from "react-hot-toast";
import useClient from "../Helpers/axiosInstance";

export class transactionService {
  client = useClient();
  fetchTransactions = async () => {
    try {
      const res = await this.client.get("/transactions");
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  getTransactionPages = async (page, limit) => {
    try {
      const res = await this.client.get(
        `/transactions?page=${parseInt(page)}&limit=${parseInt(limit)}`
      );
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  searchTransaction = async (search) => {
    try {
      const res = await this.client.get(`/transactions?query=${search}`);
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
  filterTransaction = async (fromDate, toDate) => {
    try {
      const res = await this.client.get(
        `/transactions?from=${fromDate}&to${toDate}`
      );
      this.fetchTransactions();
      return { message: "success", data: res.data };
    } catch (err) {
      return { message: "failed" };
    }
  };
}

import React from "react";

export const vectors = {
  chat: require("../Assets/vectors/chat.png"),
};

const Vector = ({ name, className }) => {
  return <img className={className} src={vectors[name]} alt="" />;
};

export default Vector;

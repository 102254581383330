import React from "react";
import { ButtonFill } from "../../../UI/Utitls/Button";
import { Input, Select } from "../../../UI/Utitls/Input";
import useTerminal from "../../../../Hooks/useTerminal";

const Terminal = () => {
  const {
    form,
    errors,
    loading,
    merchantNames,
    handleSelect,
    handleChange,
    // handleSubmit,
    updateTerminal_,
  } = useTerminal();
  
  return (
    <div className="flex flex-col mb-[43px]">
      <div className="grid grid-cols-12 py-[44px] border-y">
        <span className="col-span-4">Merchant Details</span>
        <div className="col-span-8 ">
          <div className="max-w-[534px] flex flex-col gap-[29px]">
            <Select
              label={"Merchant Name"}
              value={form.merchantName}
              handleChange={(e) => handleSelect(e, "merchantName")}
              name={"merchantName"}
              error={errors.merchantName}
              options={merchantNames}
            />
            <Input
              label={"Terminal ID"}
              value={form.tid}
              handleChange={handleChange}
              name={"tid"}
              error={errors.tid}
            />
            <Input
              label={"Terminal Type"}
              value={form.type}
              handleChange={handleChange}
              name={"type"}
              error={errors.type}
            />
            <Input
              label={"Operator"}
              value={form.operator}
              handleChange={handleChange}
              name={"operator"}
              error={errors.operator}
            />
            <Input
              label={"Slip Header"}
              value={form.slipHeader}
              handleChange={handleChange}
              name={"slipHeader"}
              error={errors.slipHeader}
            />
            <Input
              label={"Slip Footer"}
              value={form.slipFooter}
              handleChange={handleChange}
              name={"slipFooter"}
              error={errors.slipFooter}
            />{" "}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 items-end w-full ">
        <span className="col-span-4"> </span>
        <div className="col-span-8 pt-4 ">
          <ButtonFill
            label={"Save"}
            classes="h-[55px] max-w-[543px] w-full text-[#fff] "
            action={updateTerminal_}
            loading={loading}
            active={true}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 py-[44px]">
        <span className="col-span-4">Activity</span>
        <div className="col-span-8 flex flex-col gap-[28px]">
          <div className="flex items-center gap-[11px]">
            <span className="bg-[#5db86c1a] text-[#5DB86C] py-[8px] px-[10px] rounded-[36px]">
              Active
            </span>
            <span className="text-[14px] text-[gray_text]">
              Last Seen: 3 Minutes ago
            </span>
          </div>
          <div className="flex items-center gap-[11px]">
            <span className="flex-[0.4] rounded-[36px]">Created At:</span>
            <span className="flex-[0.6] text-[14px] text-[gray_text]"></span>
          </div>
          <div className="flex items-center gap-[11px]">
            <span className="flex-[0.4] rounded-[36px]">Updated At:</span>
            <span className="flex-[0.6] text-[14px] text-[gray_text]"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terminal;

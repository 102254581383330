import React, { useState } from "react";
import { useDataContext } from "../../../State/context/DataContext";
import { useFlowContext } from "../../../State/context/FlowContext";
import Table from "./Table";
import { merchantService } from "../../../Services/merchants.service";

const header = [
  { name: "ID", ratio: 15 },
  { name: "Name", ratio: 20 },
  { name: "Email", ratio: 20 },
  { name: "Phone Number", ratio: 15 },
  { name: "Status", ratio: 15 },
];

const CustomCell = ({ val, className }) => {
  return <span className={className}>{val}</span>;
};

const MerchantTable = ({ showMerchant }) => {
  const { merchants, setMerchants } = useDataContext();
  const service = new merchantService();
  const { setRowId, setEntity } = useFlowContext();
  const [checkedList, setCheckedList] = useState([]);
  const append = (id) => setCheckedList([...checkedList, id]);
  const remove = (id) =>
    setCheckedList([...checkedList.filter((c) => c === id)]);
  // console.log(merchants);
  const viewRow = (id) => {
    setRowId(id);
    const item = merchants?.data?.find((item) => item.id === id);
    // console.log(item, id);
    setEntity({
      id: item?.id,
      name: item?.name?.split(" ")[0][0] + item?.name?.split(" ")[1][0],
      descrption: { label: "", value: "" },
      type: "regular",
      hasActions: true,
      activate: () => {},
      deactivate: () => {},
    });
    showMerchant();
  };
  const switchPage = async (page) => {
    const res = await service.getMerchantPages(page, 10);
    // console.log(res.data);
    if (res.message === "success") {
      setMerchants(res.data);
    }
    return { message: res.message };
  };

  const createdata = (ID, name, email, id, phone, status) => {
    let status_ = status === 1 ? "active" : "inactive";

    const getParam = (val) => {
      if (val === "active") {
        return (
          <CustomCell
            val={val}
            className={
              "w-fit p-3 bg-[#5db86c1a] capitalize text-[#5DB86C] h-[36px] flex items-center justify-center rounded-[36px]"
            }
          />
        );
      } else if (val === "inactive") {
        return (
          <CustomCell
            val={val}
            className={
              "w-fit p-3 bg-[#da34420a] capitalize text-[#da3442] h-[36px] flex items-center justify-center rounded-[36px]"
            }
          />
        );
      } else {
        // console.log(val);
        return val;
      }
    };
    const row = [
      { name: getParam(ID), id: id, root: "ID" },
      { name: getParam(name), id: id, root: "Name" },
      { name: getParam(email), id: id, root: "Email" },
      { name: getParam(phone), id: id, root: "Phone Number" },
      { name: getParam(status_), id: id, root: "Status" },
    ];

    return row;
  };
  const data = merchants
    ? merchants?.data?.map((t) =>
        createdata(t.mid, t.name, t.contact_email, t.id, t.contact_phone, 1)
      )
    : [];
  return (
    <Table
      checkedList={checkedList}
      append={append}
      remove={remove}
      data={[...data]}
      header={header}
      viewRow={viewRow}
      paginationData={{
        limit: merchants?.limit,
        page: merchants?.page,
        total: merchants?.total,
        "more-pages": merchants["more-pages"],
        navigatePage: switchPage,
      }}
      deleteParam={{
        title: "Merchant",
        label:
          "You are about to delete a merchant. Please click confirm to delete the merchant",
      }}
    />
  );
};

export default MerchantTable;

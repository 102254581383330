import React from "react";
import useUpdateUser from "../../../../Hooks/useUpdateUser";
import { Input, Select } from "../../../UI/Utitls/Input";
// import { useFlowContext } from "../../../../State/context/FlowContext";

const UserInfo = () => {
  const { form, handleChange, errors, userRoles, banks, handleSelect } = useUpdateUser();

  return (
    <div className="flex flex-col mb-[43px]">
      <div className="grid grid-cols-12 py-[44px] border-y">
        <span className="col-span-4">Personal Details</span>
        <div className="col-span-8 ">
          <div className="max-w-[534px] flex flex-col gap-[29px]">
            <Input
              label={"First Name"}
              value={form.first_name}
              handleChange={handleChange}
              name={"first_name"}
              error={errors.first_name}
            />
            <Input
              label={"Last Name"}
              value={form.last_name}
              handleChange={handleChange}
              name={"last_name"}
              error={errors.last_name}
            />
            <Input
              label={"Email"}
              value={form.email}
              handleChange={handleChange}
              name={"email"}
              error={errors.email}
            />
            <Input
              label={"Phone"}
              value={form.phone}
              handleChange={handleChange}
              name={"phone"}
              error={errors.phone}
            />
            <Select
              label={"Role"}
              value={form.role.name}
              handleChange={(e) => handleSelect(e, "role")}
              name={"role"}
              error={errors.role}
              options={userRoles}
            />
            {(form.role.name === "Merchant" || form.role.name === "Bank") && (
              <>
                <Select
                  label={"Select Bank"}
                  value={form.bank.name}
                  handleChange={(e) => handleSelect(e, "bank")}
                  name={"bank"}
                  error={errors.bank}
                  options={banks}
                />
                {form.role.name === "Merchant" && (
                  <Input
                    label={"Account Number"}
                    value={form.account_number}
                    handleChange={handleChange}
                    name={"account_number"}
                    error={errors.account_number}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 py-[44px]">
        <span className="col-span-4">Activity</span>
        <div className="col-span-8 flex flex-col gap-[28px]">
          
          <div className="flex items-center gap-[67px]">
            <span className="text-[--dark_text] text-[14px] ">
              Created At: 
            </span>
            <span className="text-[14px] text-[gray_text]">
              2023-08-12, 04:30PM
            </span>
          </div>
          <div className="flex items-center gap-[67px]">
            <span className="text-[--dark_text] text-[14px] ">
              Updated At: 
            </span>
            <span className="text-[14px] text-[gray_text]">
              2023-08-12, 04:30PM
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;

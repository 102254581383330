import React, { useState } from "react";
import Icon from "../../../Constants/icons";
import { useNavigate } from "react-router-dom";
import { useFlowContext } from "../../../State/context/FlowContext";

const SidebarCard = ({ title, children_, icon, path, action }) => {
  const {
    // setPage,
    setCreateScreen,
  } = useFlowContext();
  const route = window.location.pathname;

  const active = route === path;
  const navigate = useNavigate();
  const [children, setChildren] = useState(false);
  // const setPageTitle = (pathname) => {
  //   if (pathname === "/login" || pathname === "/dashboard") {
  //     setPage("Overview");
  //   } else {
  //     const page = path.split("/");
  //     console.log(page);
  //     const pageName = page[page.length - 1];
  //     setPage(pageName.replace("-", " "));
  //   }
  // };
  const changeRoute = () => {
    action && action();
    if (children_) {
      setChildren(!children);
      navigate(children_[0].path);
      // setPageTitle(children_[0].path);
      if (children_?.find((child) => child.path === route)) {
      }
    } else {
      navigate(path);
      // setPageTitle(path);
    }
    setCreateScreen(false);
  };
  return (
    <div className={`w-full min-h-[35px] flex flex-col gap-2 `}>
      <div
        onClick={changeRoute}
        className={`h-[35px] transition-all duration-75 px-[20px] rounded-[8px] w-full flex cursor-pointer items-center justify-between ${
          !children_ && active ? "bg-[#4834D41A] text-[#4834D4]" : "text-[#333]"
        }`}
      >
        <div className="flex items-center gap-[10px] text-[16px] font-[400]">
          <Icon
            name={active ? "active_" + icon : icon}
            className={"w-[22px] h-[22px]"}
          />
          {title}
        </div>
        {children_ && <Icon name={"chevron"} className={"w-[16px] h-[16px]"} />}
      </div>
      {children && children_ && (
        <div className="flex flex-col pl-[20px]">
          {children_?.map((child, n) => (
            <SidebarCard
              key={n}
              title={child?.title}
              icon={child.icon}
              path={child.path}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SidebarCard;

import React from "react";
import AuthLayout from "../UI/Layouts/AuthLayout";
import AuthForm from "../UI/Forms/AuthForm";
import useAuthentication from "../../Hooks/useAuthentication";
import { Input, PasswordInput } from "../UI/Utitls/Input";
import { AiFillCheckSquare, AiOutlineCheckSquare } from "react-icons/ai";
import { Link } from "react-router-dom";

const Signup = () => {
  const {
    form,
    errors,
    singupLayoutLabels,
    loading,
    terms,
    active,
    setTerms,
    handleChange,
    handleSignup,
  } = useAuthentication();
  return (
    <AuthLayout>
      <AuthForm
        loading={loading}
        labels={singupLayoutLabels}
        handleSubmit={handleSignup}
        btn={"Create Account"}
        active={active}
      >
        <Input
          label={"Enter your email address"}
          value={form.email}
          handleChange={handleChange}
          name={"email"}
          error={errors.email}
        />
        <PasswordInput
          label={"Enter your password"}
          value={form.password}
          handleChange={handleChange}
          name={"password"}
          error={errors.password}
        />
      </AuthForm>
      <div className="flex gap-[16px] absolute bottom-0">
        {terms ? (
          <AiFillCheckSquare
            onClick={() => setTerms(!terms)}
            className={`text-[--dark] text-[25px] cursor-pointer `}
          />
        ) : (
          <AiOutlineCheckSquare
            onClick={() => setTerms(!terms)}
            className={`text-[--dark] text-[25px] cursor-pointer `}
          />
        )}
        <span className="leading-[20px] text-[16px] font-[400]">
          I agree to the{" "}
          <Link to={"#"} className="text-[--dark]">
            Terms of Service
          </Link>{" "}
          and give consent to collect, use and store my data according to your{" "}
          <Link to={"#"} className="text-[--dark]">
            Privacy Policy
          </Link>
          .
        </span>
      </div>
    </AuthLayout>
  );
};

export default Signup;

import React from "react";
// import { Formik, Form } from "formik";
import { ButtonFill } from "../Utitls/Button";

const AuthForm = ({
  handleSubmit,
  children,
  loading,
  active,
  labels,
  btn,
  centered,
  base_link,
  otp,
  hideBtn,
}) => {
  return (
    <form
      className={`flex flex-col gap-[74px] w-full max-w-[543px] ${
        centered && "items-center"
      }`}
    >
      <div className={`flex flex-col gap-[12px] ${centered && "items-center"}`}>
        <span className="text-[--dark_text] text-[36px] font-[400]">
          {labels.title}
        </span>
        <span className="text-[--dark_text] text-[20px] font-[400]">
          {labels.subtitle}
        </span>
      </div>
      <div
        className={`flex flex-col w-full  ${
          otp ? "gap-[72px]" : "gap-[32px]"
        } ${centered && "items-center"}`}
      >
        {children}

        {!hideBtn && (
          <ButtonFill
            label={btn}
            loading={loading}
            action={handleSubmit}
            active={active}
            classes={"h-[45px] w-full text-[#fff]"}
          />
        )}
        {base_link && base_link}
      </div>
    </form>
  );
};

export default AuthForm;
